.t-slds {
  position: relative;
  overflow: hidden
}

.t-slds__bgimg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}

.t-slds__bgimg-contain {
  background-size: contain
}

.t-slds__iframe {
  position: relative
}

.t-slds__iframe__body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.t-slds__img {
  display: block;
  width: 100%
}

.t-slds__container {
  overflow: hidden;
  position: relative
}

.t-slds__items-wrapper {
  position: relative;
  overflow: hidden;
  -webkit-transition: height ease-in-out .3s;
  -moz-transition: height ease-in-out .3s;
  -o-transition: height ease-in-out .3s;
  transition: height ease-in-out .3s
}

.t-slds__nocycle .t-slds__item:first-child,
.t-slds__nocycle .t-slds__item:last-child {
  opacity: 0 !important
}

.t-slds__nocycle .t-slds__arrow_wrapper-left {
  display: none
}

.t-slds__items-wrapper:before,
.t-slds__items-wrapper:after {
  content: '';
  display: table;
  clear: both
}

.t-slds_animated-none {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -o-transform: none !important;
  transform: none !important
}

.t-slds_animated.t-slds_animated-none {
  -webkit-transition: height ease-in-out .3s;
  -moz-transition: height ease-in-out .3s;
  -o-transition: height ease-in-out .3s;
  transition: height ease-in-out .3s
}

.t-slds_animated.t-slds_animated-none .t-slds__item {
  -webkit-transition: opacity ease-in-out .3s;
  -moz-transition: opacity ease-in-out .3s;
  -o-transition: opacity ease-in-out .3s;
  transition: opacity ease-in-out .3s
}

.t-slds_animated.t-slds_animated-fast {
  -webkit-transition: height ease-in-out .3s, transform ease-in-out .3s;
  -moz-transition: height ease-in-out .3s, transform ease-in-out .3s;
  -o-transition: height ease-in-out .3s, transform ease-in-out .3s;
  transition: height ease-in-out .3s, transform ease-in-out .3s
}

.t-slds_animated.t-slds_animated-slow {
  -webkit-transition: height ease-in-out .5s, transform ease-in-out .5s;
  -moz-transition: height ease-in-out .5s, transform ease-in-out .5s;
  -o-transition: height ease-in-out .5s, transform ease-in-out .5s;
  transition: height ease-in-out .5s, transform ease-in-out .5s
}

.t-slds__item {
  float: left
}

.t-slds_animated-none .t-slds__item {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  float: none
}

.t-slds_animated-none .t-slds__item.t-slds__item-loaded {
  display: block
}

.t-slds_animated-none .t-slds__item.t-slds__item_active {
  opacity: 1;
  z-index: 1
}

.t-slds_ie .t-slds__item {
  display: none !important
}

.t-slds_ie .t-slds__item_active {
  display: block !important
}

.t-slds__arrow_container-outside {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  width: 100%
}

.t-slds__arrow_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15%;
  cursor: pointer;
  z-index: 15;
  -webkit-transition: height ease-in-out .3s;
  -moz-transition: height ease-in-out .3s;
  -o-transition: height ease-in-out .3s;
  transition: height ease-in-out .3s
}

.t-slds__arrow_wrapper-left {
  left: 0
}

.t-slds__arrow_wrapper-right {
  right: 0
}

.t-slds__arrow {
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  display: inline-block
}

.t-slds__arrow-left {
  left: 30px
}

.t-slds__arrow-right {
  right: 30px
}

@media screen and (min-width:960px) {
  .t-slds__arrow-nearpic .t-slds__arrow-left {
    right: 40px;
    -moz-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    -o-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    left: auto
  }
  .t-slds__arrow-nearpic .t-slds__arrow-right {
    left: 40px;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    right: auto
  }
}

@media screen and (min-width:1200px) {
  .t-slds__arrow-nearpic .t-slds__arrow-left {
    right: 50px
  }
  .t-slds__arrow-nearpic .t-slds__arrow-right {
    left: 50px
  }
}

.t-slds__arrow-withbg {
  border-radius: 100px;
  background-color: #eee;
  -webkit-transition: background-color ease-in-out .2s, border-color ease-in-out .2s;
  -moz-transition: background-color ease-in-out .2s, border-color ease-in-out .2s;
  -o-transition: background-color ease-in-out .2s, border-color ease-in-out .2s;
  transition: background-color ease-in-out .2s, border-color ease-in-out .2s
}

.t-slds__arrow-withbg .t-slds__arrow_body {
  position: absolute;
  top: 50%;
  left: 50%
}

.t-slds__arrow-withbg .t-slds__arrow_body-left {
  -webkit-transform: translate(-55%, -50%) rotate(180deg);
  -moz-transform: translate(-55%, -50%) rotate(180deg);
  -o-transform: translate(-55%, -50%) rotate(180deg);
  transform: translate(-55%, -50%) rotate(180deg)
}

.t-slds__arrow-withbg .t-slds__arrow_body-right {
  -webkit-transform: translate(-45%, -50%);
  -moz-transform: translate(-45%, -50%);
  -o-transform: translate(-45%, -50%);
  transform: translate(-45%, -50%)
}

.t-slds__arrow_body-left {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg)
}

.t-slds__bullet_wrapper {
  text-align: center;
  position: relative;
  z-index: 15;
  padding: 16px 0 0;
  width: 100%;
  font-size: 0
}

.t-slds__container .t-slds__bullet_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0
}

.t-slds__container .t-slds__bullet_wrapper {
  padding-bottom: 16px
}

.t-slds__bullet {
  display: inline-block;
  padding: 10px;
  cursor: pointer
}

.t-slds__bullet_body {
  background-color: #eee;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  -webkit-transition: all ease-in-out .2s;
  -moz-transition: all ease-in-out .2s;
  -o-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  box-sizing: border-box
}

.t-slds__caption__container {
  position: relative;
  margin-top: 16px
}

.t-slds__caption_wrapper {
  border-top: 1px solid #eee;
  padding: 14px 0
}

.t-slds__caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity ease-in-out .3s;
  -moz-transition: opacity ease-in-out .3s;
  -o-transition: opacity ease-in-out .3s;
  transition: opacity ease-in-out .3s
}

.t-slds__caption-active {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  opacity: 1
}

.t-slds__descr {
  margin-top: 5px;
  color: #777
}

@media screen and (max-width:960px) {
  .t-width .t-slds__wrapper {
    padding: 0 20px
  }
  .t-col_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0
  }
  .t-width_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0 20px
  }
  .t-slds__arrow-left {
    left: 20px
  }
  .t-slds__arrow-right {
    right: 20px
  }
}

@media screen and (max-width:640px) {
  .t-slds__arrow-left {
    left: 10px
  }
  .t-slds__arrow-right {
    right: 10px
  }
  .t-slds__arrow-withbg {
    width: 24px !important;
    height: 24px !important
  }
  .t-slds__arrow_body {
    width: 10px !important
  }
  .t-slds__arrow-withbg .t-slds__arrow_body {
    width: 6px !important
  }
  .t-col .t-slds__witharrows .t-slds__wrapper {
    padding: 0 15px
  }
  .t-width .t-slds__witharrows .t-slds__wrapper {
    padding: 0 35px
  }
  .t-col_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0 15px
  }
  .t-width_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0 35px
  }
}

.t-slds__play {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: 2
}

.t-slds__play_icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  margin: 0 auto;
  -webkit-transition: all ease-in-out .2s;
  -moz-transition: all ease-in-out .2s;
  -o-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s
}

.t-slds__play:hover .t-slds__play_icon {
  -moz-transform: translateY(-50%) scale(1.15);
  -ms-transform: translateY(-50%) scale(1.15);
  -webkit-transform: translateY(-50%) scale(1.15);
  -o-transform: translateY(-50%) scale(1.15);
  transform: translateY(-50%) scale(1.15)
}

.t-slds__frame {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.t-slds__thumbsbullet-wrapper {
  font-size: 0
}

.t-slds__thumbsbullet.t-slds__bullet {
  padding: 0;
  width: 60px;
  position: relative;
  -webkit-transition: opacity ease-in-out .3s;
  -moz-transition: opacity ease-in-out .3s;
  -o-transition: opacity ease-in-out .3s;
  transition: opacity ease-in-out .3s;
  margin-right: 5px;
  margin-top: 4px
}

.t-slds__thumbsbullet.t-slds__bullet:not(.t-slds__bullet_active):hover {
  opacity: .7
}

.t-slds__thumbsbullet-border {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 2px solid transparent;
  -webkit-transition: border-color ease-in-out .3s;
  -moz-transition: border-color ease-in-out .3s;
  -o-transition: border-color ease-in-out .3s;
  transition: border-color ease-in-out .3s
}

.t-slds__thumbsbullet.t-slds__bullet_active .t-slds__thumbsbullet-border {
  border-color: #222
}

@media screen and (max-width:640px) {
  .t-slds__thumbsbullet.t-slds__bullet {
    width: 40px;
    margin-right: 4px;
    margin-top: 3px
  }
}