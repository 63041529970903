.t-clear,
.t-container:after,
.t-container_100:after,
.t-container_10:after,
.t-container_8:after {
  clear: both
}

*,
:after,
:before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

a,
b,
blockquote,
center,
code,
div,
h1,
h2,
h3,
h4,
h5,
h6,
i,
img,
p,
pre,
span,
table,
td,
th,
tr,
u,
video {
  margin: 0;
  padding: 0;
  border: 0
}

.t-row {
  margin: 0
}

.t-container,
.t-container_10,
.t-container_8 {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 100%
}

.t-container {
  max-width: 1200px
}

.t-container.flexx,
.t-container_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.t-container_100 {
  width: 100%;
  padding: 0
}

.t-container_10 {
  max-width: 1000px
}

.t-container_8 {
  max-width: 800px
}

.t-container:after,
.t-container:before,
.t-container_100:after,
.t-container_100:before,
.t-container_10:after,
.t-container_10:before,
.t-container_8:after,
.t-container_8:before {
  display: table;
  content: " "
}

.t-col {
  display: inline;
  float: left;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%
}

.t-col.flexx,
.t-col_flex {
  align-items: stretch;
  height: inherit;
  margin-top: auto;
  margin-bottom: auto
}

.t-col_1 {
  max-width: 60px
}

.t-col_2 {
  max-width: 160px
}

.t-col_3 {
  max-width: 260px
}

.t-col_4 {
  max-width: 360px
}

.t-col_5 {
  max-width: 460px
}

.t-col_6 {
  max-width: 560px
}

.t-col_7 {
  max-width: 660px
}

.t-col_8 {
  max-width: 760px
}

.t-col_9 {
  max-width: 860px
}

.t-col_10 {
  max-width: 960px
}

.t-col_11 {
  max-width: 1060px
}

.t-col_12 {
  max-width: 1160px
}

.t-col_100 {
  max-width: 100vw
}

.t-prefix_1 {
  padding-left: 100px
}

.t-prefix_2 {
  padding-left: 200px
}

.t-prefix_3 {
  padding-left: 300px
}

.t-prefix_4 {
  padding-left: 400px
}

.t-prefix_5 {
  padding-left: 500px
}

.t-prefix_6 {
  padding-left: 600px
}

.t-prefix_7 {
  padding-left: 700px
}

.t-prefix_8 {
  padding-left: 800px
}

.t-prefix_9 {
  padding-left: 900px
}

.t-prefix_10 {
  padding-left: 1000px
}

.t-prefix_11 {
  padding-left: 1100px
}

.t-prefix_12 {
  padding-left: 1200px
}

.t-width {
  width: 100%
}

.t-width_1 {
  max-width: 60px
}

.t-width_2 {
  max-width: 160px
}

.t-width_3 {
  max-width: 260px
}

.t-width_4 {
  max-width: 360px
}

.t-width_5 {
  max-width: 460px
}

.t-width_6 {
  max-width: 560px
}

.t-width_7 {
  max-width: 660px
}

.t-width_8 {
  max-width: 760px
}

.t-width_9 {
  max-width: 860px
}

.t-width_10 {
  max-width: 960px
}

.t-width_11 {
  max-width: 1060px
}

.t-width_12 {
  max-width: 1160px
}

.t-width_100 {
  max-width: 100%
}

.t-cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  margin-left: 0;
  margin-right: 0
}

.t-cell_25 {
  width: 25vw
}

.t-cell_33 {
  width: 33.3333333vw
}

.t-cell_50 {
  width: 50vw
}

.t-cell_100 {
  width: 100vw
}

@media screen and (max-width:1200px) {
  .t-container {
    max-width: 960px;
    padding: 0
  }
  .t-container_10 {
    max-width: 780px
  }
  .t-container_8 {
    max-width: 640px
  }
  .t-col {
    display: inline;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%
  }
  .t-col_1 {
    max-width: 60px
  }
  .t-col_2 {
    max-width: 140px
  }
  .t-col_3 {
    max-width: 220px
  }
  .t-col_4 {
    max-width: 300px
  }
  .t-col_5 {
    max-width: 380px
  }
  .t-col_6 {
    max-width: 460px
  }
  .t-col_7 {
    max-width: 540px
  }
  .t-col_8 {
    max-width: 620px
  }
  .t-col_9 {
    max-width: 700px
  }
  .t-col_10 {
    max-width: 780px
  }
  .t-col_11 {
    max-width: 860px
  }
  .t-col_12 {
    max-width: 940px
  }
  .t-prefix_1 {
    padding-left: 80px
  }
  .t-prefix_2 {
    padding-left: 160px
  }
  .t-prefix_3 {
    padding-left: 240px
  }
  .t-prefix_4 {
    padding-left: 320px
  }
  .t-prefix_5 {
    padding-left: 400px
  }
  .t-prefix_6 {
    padding-left: 480px
  }
  .t-prefix_7 {
    padding-left: 560px
  }
  .t-prefix_8 {
    padding-left: 640px
  }
  .t-prefix_9 {
    padding-left: 720px
  }
  .t-prefix_10 {
    padding-left: 800px
  }
  .t-prefix_11 {
    padding-left: 880px
  }
  .t-prefix_12 {
    padding-left: 960px
  }
  .t-width_1 {
    max-width: 60px
  }
  .t-width_2 {
    max-width: 140px
  }
  .t-width_3 {
    max-width: 220px
  }
  .t-width_4 {
    max-width: 300px
  }
  .t-width_5 {
    max-width: 380px
  }
  .t-width_6 {
    max-width: 460px
  }
  .t-width_7 {
    max-width: 540px
  }
  .t-width_8 {
    max-width: 620px
  }
  .t-width_9 {
    max-width: 700px
  }
  .t-width_10 {
    max-width: 780px
  }
  .t-width_11 {
    max-width: 860px
  }
  .t-width_12 {
    max-width: 940px
  }
  .t-width_100 {
    max-width: 100%
  }
}

@media screen and (max-width:960px) {
  .t-col,
  .t-container.flexx,
  .t-container_flex {
    display: block
  }
  .t-container {
    max-width: 640px
  }
  .t-col,
  .t-col_1,
  .t-col_10,
  .t-col_11,
  .t-col_12,
  .t-col_2,
  .t-col_3,
  .t-col_4,
  .t-col_5,
  .t-col_6,
  .t-col_7,
  .t-col_8,
  .t-col_9 {
    width: 100%;
    max-width: 100%
  }
  .t-col {
    float: none;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    box-sizing: border-box
  }
  .t-prefix_1,
  .t-prefix_10,
  .t-prefix_11,
  .t-prefix_12,
  .t-prefix_2,
  .t-prefix_3,
  .t-prefix_4,
  .t-prefix_5,
  .t-prefix_6,
  .t-prefix_7,
  .t-prefix_8,
  .t-prefix_9 {
    padding-left: none
  }
}