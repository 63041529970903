.t-zoomer__show_fixed {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden
}

.t-zoomer__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all ease-in-out .2s;
  -moz-transition: all ease-in-out .2s;
  -o-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.t-zoomer__show .t-zoomer__wrapper {
  z-index: 9999999;
  opacity: 1
}

.t-zoomer__container {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0
}

.t-zoomer__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  cursor: -webkit-zoom-out;
  cursor: zoom-out
}

.t-zoomer__comments {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  height: auto;
  padding: 14px 0 0;
  text-align: center;
  max-width: 700px;
  box-sizing: border-box;
  margin: 0 auto
}

.t-zoomer__descr {
  z-index: 3
}

.t-zoomable {
  cursor: -webkit-zoom-in;
  cursor: zoom-in
}

.t-zoomer__show .t-zoomable,
.t-zoomer__show .t-carousel__zoomer__inner {
  cursor: -webkit-zoom-out;
  cursor: zoom-out
}

.t-zoomer__container {
  z-index: 99999999
}

.t-carousel__zoomed,
.t-carousel__zoomer__slides,
.t-carousel__zoomer__inner,
.t-carousel__zoomer__item,
.t-carousel__zoomer__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.t-carousel__zoomer__inner {
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px
}

.t-carousel__zoomer__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  overflow: auto
}

.t-carousel__zoomer__item {
  height: 100%;
  display: none
}

.t-zoomer__close {
  position: fixed;
  right: 10px;
  top: 12px;
  width: 29px;
  height: 32px;
  cursor: pointer;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  z-index: 999999999999
}

.t-zoomer__close:hover {
  opacity: .7
}

.t-zoomer__close-line {
  position: absolute;
  left: 15px;
  height: 26px;
  width: 2px;
  background-color: #222
}

.t-zoomer__close-line-first {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg)
}

.t-zoomer__close-line-second {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.t-carousel__zoomer__control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  cursor: pointer;
  z-index: 99
}

.t-carousel__zoomer__control_right {
  left: auto;
  right: 0
}

.t-carousel__zoomer__control:hover {
  opacity: .6
}

.t-carousel__zoomer__arrow {
  width: 20px;
  height: 20px;
  background: transparent;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg)
}

.t-carousel__zoomer__arrow__wrapper {
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%
}

.t-carousel__zoomer__arrow__wrapper_left {
  left: 18px
}

.t-carousel__zoomer__arrow__wrapper_right {
  right: 18px
}

.t-carousel__zoomer__arrow_right {
  border-top: 2px solid;
  border-right: 2px solid
}

.t-carousel__zoomer__arrow_left {
  border-left: 2px solid;
  border-bottom: 2px solid
}

@media screen and (max-width:768px) {
  .t-carousel__zoomer__control .t-carousel__zoomer__arrow {
    width: 12px;
    height: 12px
  }
  .t-carousel__zoomer-control {
    width: 10%
  }
  .t-carousel__zoomer__arrow__left {
    left: 15px
  }
  .t-carousel__zoomer__arrow__right {
    right: 15px
  }
}