@media screen and (min-width:980px) {
  .t-animate[data-animate-style=fadein],
  .t-animate[data-animate-style=fadeinup],
  .t-animate[data-animate-style=fadeindown],
  .t-animate[data-animate-style=fadeinleft],
  .t-animate[data-animate-style=fadeinright],
  .t-animate[data-animate-style=zoomin],
  .t-animate[data-animate-style=zoomout] {
    opacity: 0;
    -webkit-transition-property: opacity, transform;
    transition-property: opacity, transform;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    -webkit-backface-visibility: hidden
  }
  .t-title.t-animate {
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s
  }
  .t-descr.t-animate,
  .t-subtitle.t-animate,
  .t-text.t-animate,
  .t-uptitle.t-animate {
    -webkit-transition-duration: .7s;
    transition-duration: .7s
  }
  .t-item.t-animate {
    -webkit-transition-duration: .5s;
    transition-duration: .5s
  }
  .t-animate_started[data-animate-style=fadein] {
    opacity: 1
  }
  .t-animate[data-animate-style=fadeinup] {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px)
  }
  .t-animate_started[data-animate-style=fadeinup] {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
  .t-animate[data-animate-style=fadeindown] {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px)
  }
  .t-animate_started[data-animate-style=fadeindown] {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
  .t-animate[data-animate-style=fadeinleft] {
    -webkit-transform: translate(100px, 0);
    transform: translate(100px, 0)
  }
  .t-animate_started[data-animate-style=fadeinleft] {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
  .t-animate[data-animate-style=fadeinright] {
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0)
  }
  .t-animate_started[data-animate-style=fadeinright] {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
  .t-animate[data-animate-style=zoomin] {
    -webkit-transform: scale(.9);
    transform: scale(.9)
  }
  .t-animate_started[data-animate-style=zoomin] {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  .t-animate[data-animate-style=zoomout] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }
  .t-animate_started[data-animate-style=zoomout] {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  .t-animate_started[data-animate-distance],
  .t-animate_started[data-animate-scale] {
    -webkit-transform: none !important;
    transform: none !important
  }
}