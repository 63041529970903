@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&subset=latin,cyrillic);
.t-body {
  margin: 0
}

#allrecords {
  -webkit-font-smoothing: antialiased;
  background-color: none
}

#allrecords a {
  color: #ff8562;
  text-decoration: none
}

#allrecords a[href^=tel] {
  color: inherit;
  text-decoration: none
}

#allrecords ol {
  padding-left: 22px
}

#allrecords ul {
  padding-left: 20px
}

@media print {
  body,
  html {
    min-width: 1200px;
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
    border: none
  }
}

.t-text {
  font-family: 'Roboto', serif;
  font-weight: 300;
  color: #000
}

.t-text_xs {
  font-size: 15px;
  line-height: 1.55
}

.t-text_sm {
  font-size: 18px;
  line-height: 1.55
}

.t-text_md {
  font-size: 20px;
  line-height: 1.55
}

.t-text_lg {
  font-size: 22px;
  line-height: 1.55
}

.t-text_weight_plus {
  font-weight: 400
}

.t-text-impact {
  font-family: 'Roboto', serif;
  font-weight: 300;
  color: #000
}

.t-text-impact_xs {
  font-size: 26px;
  line-height: 1.5
}

.t-text-impact_sm {
  font-size: 32px;
  line-height: 1.35
}

.t-text-impact_md {
  font-size: 38px;
  line-height: 1.35
}

.t-text-impact_lg {
  font-size: 42px;
  line-height: 1.23
}

.t-name {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #000
}

.t-name_xs {
  font-size: 16px;
  line-height: 1.35
}

.t-name_sm {
  font-size: 18px;
  line-height: 1.35
}

.t-name_md {
  font-size: 20px;
  line-height: 1.35
}

.t-name_lg {
  font-size: 22px;
  line-height: 1.35
}

.t-name_xl {
  font-size: 24px;
  line-height: 1.35
}

.t-heading {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #000
}

.t-heading_xs {
  font-size: 26px;
  line-height: 1.23
}

.t-heading_sm {
  font-size: 28px;
  line-height: 1.17
}

.t-heading_md {
  font-size: 30px;
  line-height: 1.17
}

.t-heading_lg {
  font-size: 32px;
  line-height: 1.17
}

.t-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #000
}

.t-title_xxs {
  font-size: 36px;
  line-height: 1.23
}

.t-title_xs {
  font-size: 42px;
  line-height: 1.23
}

.t-title_sm {
  font-size: 48px;
  line-height: 1.23
}

.t-title_md {
  font-size: 52px;
  line-height: 1.23
}

.t-title_lg {
  font-size: 64px;
  line-height: 1.23
}

.t-title_xl {
  font-size: 72px;
  line-height: 1.17
}

.t-title_xxl {
  font-size: 82px;
  line-height: 1.17
}

.t-descr {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #000
}

.t-descr_xxs {
  font-size: 14px;
  line-height: 1.55
}

.t-descr_xs {
  font-size: 16px;
  line-height: 1.55
}

.t-descr_sm {
  font-size: 18px;
  line-height: 1.55
}

.t-descr_md {
  font-size: 20px;
  line-height: 1.55
}

.t-descr_lg {
  font-size: 22px;
  line-height: 1.55
}

.t-descr_xl {
  font-size: 24px;
  line-height: 1.5
}

.t-descr_xxl {
  font-size: 26px;
  line-height: 1.45
}

.t-descr_xxxl {
  font-size: 30px;
  line-height: 1.45;
  letter-spacing: .45
}

.t-uptitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #000;
  letter-spacing: 2.5px
}

.t-uptitle_xs {
  font-size: 12px
}

.t-uptitle_sm {
  font-size: 14px
}

.t-uptitle_md {
  font-size: 16px
}

.t-uptitle_lg {
  font-size: 18px
}

.t-uptitle_xl {
  font-size: 20px;
  letter-spacing: 2px
}

.t-uptitle_xxl {
  font-size: 22px;
  letter-spacing: 2px
}

.t-uptitle_xxxl {
  font-size: 24px;
  letter-spacing: 2px
}

@media screen and (max-width:1200px) {
  .t-text_xs {
    font-size: 14px
  }
  .t-text_sm {
    font-size: 16px
  }
  .t-text_md {
    font-size: 18px
  }
  .t-text_lg {
    font-size: 20px
  }
  .t-text-impact_md {
    font-size: 30px
  }
  .t-descr_xxs {
    font-size: 12px
  }
  .t-descr_xs {
    font-size: 14px
  }
  .t-descr_sm {
    font-size: 16px
  }
  .t-descr_md {
    font-size: 18px
  }
  .t-descr_lg {
    font-size: 20px
  }
  .t-descr_xl {
    font-size: 22px
  }
  .t-descr_xxl {
    font-size: 22px
  }
  .t-descr_xxxl {
    font-size: 26px
  }
  .t-uptitle_md {
    font-size: 14px
  }
  .t-uptitle_lg {
    font-size: 16px
  }
  .t-uptitle_xl {
    font-size: 18px
  }
  .t-uptitle_xxl {
    font-size: 20px
  }
  .t-uptitle_xxxl {
    font-size: 22px
  }
  .t-title_xxs {
    font-size: 32px
  }
  .t-title_xs {
    font-size: 38px
  }
  .t-title_sm {
    font-size: 44px
  }
  .t-title_md {
    font-size: 48px
  }
  .t-title_lg {
    font-size: 60px
  }
  .t-title_xl {
    font-size: 68px
  }
  .t-title_xxl {
    font-size: 78px
  }
  .t-name_xs {
    font-size: 14px
  }
  .t-name_sm {
    font-size: 16px
  }
  .t-name_md {
    font-size: 18px
  }
  .t-name_lg {
    font-size: 20px
  }
  .t-name_xl {
    font-size: 22px
  }
  .t-heading_xs {
    font-size: 24px
  }
  .t-heading_sm {
    font-size: 26px
  }
  .t-heading_md {
    font-size: 28px
  }
  .t-heading_lg {
    font-size: 30px
  }
}

@media screen and (max-width:640px) {
  .t-text_xs {
    font-size: 12px;
    line-height: 1.45
  }
  .t-text_sm {
    font-size: 14px;
    line-height: 1.45
  }
  .t-text_md {
    font-size: 16px;
    line-height: 1.45
  }
  .t-text_lg {
    font-size: 18px;
    line-height: 1.45
  }
  .t-text-impact_sm {
    font-size: 22px
  }
  .t-text-impact_md {
    font-size: 26px
  }
  .t-text-impact_lg {
    font-size: 28px
  }
  .t-descr_xs {
    font-size: 12px;
    line-height: 1.45
  }
  .t-descr_sm {
    font-size: 14px;
    line-height: 1.45
  }
  .t-descr_md {
    font-size: 16px;
    line-height: 1.45
  }
  .t-descr_lg {
    font-size: 18px;
    line-height: 1.45
  }
  .t-descr_xl {
    font-size: 20px;
    line-height: 1.4
  }
  .t-descr_xxl {
    font-size: 20px
  }
  .t-descr_xxxl {
    font-size: 22px
  }
  .t-uptitle_xs {
    font-size: 10px
  }
  .t-uptitle_sm {
    font-size: 10px
  }
  .t-uptitle_md {
    font-size: 12px
  }
  .t-uptitle_lg {
    font-size: 14px
  }
  .t-uptitle_xl {
    font-size: 16px
  }
  .t-uptitle_xxl {
    font-size: 18px
  }
  .t-uptitle_xxxl {
    font-size: 20px
  }
  .t-title_xxs {
    font-size: 28px
  }
  .t-title_xs {
    font-size: 30px
  }
  .t-title_sm {
    font-size: 30px
  }
  .t-title_md {
    font-size: 30px
  }
  .t-title_lg {
    font-size: 30px
  }
  .t-title_xl {
    font-size: 32px
  }
  .t-title_xxl {
    font-size: 36px
  }
  .t-name_xs {
    font-size: 12px
  }
  .t-name_sm {
    font-size: 14px
  }
  .t-name_md {
    font-size: 16px
  }
  .t-name_lg {
    font-size: 18px
  }
  .t-name_xl {
    font-size: 20px
  }
  .t-heading_xs {
    font-size: 22px
  }
  .t-heading_sm {
    font-size: 24px
  }
  .t-heading_md {
    font-size: 24px
  }
  .t-heading_lg {
    font-size: 26px
  }
}

@media screen and (max-width:480px) {
  .t-title_xl {
    font-size: 30px
  }
  .t-title_xxl {
    font-size: 30px
  }
}

.t-records {
  -webkit-font_smoothing: antialiased;
  background-color: none
}

.t-records a {
  color: #ff8562;
  text-decoration: none
}

.t-records a[href^=tel] {
  color: inherit;
  text-decoration: none
}

.t-records ol {
  padding-left: 22px;
  margin-top: 0;
  margin-bottom: 10px
}

.t-records ul {
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 10px
}

.t-cover {
  height: 700px;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  vertical-align: middle;
  position: relative;
  background-attachment: fixed;
  overflow: hidden
}

.t-cover__carrier {
  height: 700px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  vertical-align: middle;
  position: relative;
  /* background-attachment: fixed */
}

.t-cover__carrier.loading {
  opacity: 0
}

.t-cover__carrier[data-content-cover-bg=""].loading {
  opacity: 1 !important
}

.t-cover__carrier.loaded {
  opacity: 1;
  transition: opacity 400ms
}

@media screen and (max-device-width:1024px) {
  .t-cover {
    background-attachment: scroll
  }
  .t-cover__carrier {
    background-attachment: scroll
  }
}

@media print {
  .t-cover {
    background-attachment: scroll
  }
  .t-cover__carrier {
    background-attachment: scroll
  }
}

.t-cover__filter {
  height: 700px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.t-cover .t-container,
.t-cover .t-container_100,
.t-cover .t-container_10,
.t-cover .t-container_8 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.t-cover__wrapper {
  height: 700px;
  display: table-cell;
  width: 1200px
}

.t-cover__wrapper span.space {
  display: inline-block;
  height: 100%;
  width: 1px
}

@media screen and (max-width:640px) {
  .t-cover {
    height: 400px;
    background-attachment: fixed
  }
  .t-cover__carrier {
    background-attachment: scroll !important;
    background-size: cover;
    background-position: center center
  }
  .t-cover__filter {
    height: 400px
  }
  .t-cover__wrapper {
    height: 400px
  }
}

@-webkit-keyframes t-arrow-bottom {
  0% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  55% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes t-arrow-bottom {
  0% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  55% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
}

.t-cover__arrow-wrapper_animated {
  animation: t-arrow-bottom 1.7s infinite ease
}

.t-cover__arrow {
  position: absolute;
  z-index: 9;
  bottom: 40px;
  right: 0;
  left: 0;
  text-align: center
}

.t-cover__arrow-wrapper {
  display: inline-block;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  cursor: pointer
}

.t-cover__arrow-wrapper:hover {
  opacity: .7
}

.t-cover__arrow-svg {
  fill: #fff
}

@media screen and (max-width:640px) {
  .t-cover__arrow_mobile {
    -moz-transform: scale(.7);
    -ms-transform: scale(.7);
    -webkit-transform: scale(.7);
    -o-transform: scale(.7);
    transform: scale(.7)
  }
  .t-cover__arrow {
    bottom: 14px
  }
}

.t-btn {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  height: 60px;
  border: 0 none;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 700;
  background-image: none;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.t-btn td {
  vertical-align: middle
}

.t-btn_sending {
  opacity: .5
}

@media screen and (max-width:640px) {
  .t-btn {
    white-space: normal;
    padding-left: 30px;
    padding-right: 30px
  }
}

.t-btn_xs {
  height: 35px;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px
}

.t-btn_sm {
  height: 45px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px
}

.t-btn_lg {
  height: 60px;
  font-size: 22px;
  padding-left: 70px;
  padding-right: 70px
}

.t-btn_xl {
  height: 80px;
  font-size: 26px;
  padding-left: 80px;
  padding-right: 80px
}

.t-btn_xxl {
  height: 100px;
  font-size: 30px;
  padding-left: 90px;
  padding-right: 90px
}

@media screen and (max-width:640px) {
  .t-btn_sm {
    padding-left: 20px;
    padding-right: 20px
  }
  .t-btn_lg {
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px
  }
  .t-btn_xl {
    font-size: 22px;
    padding-left: 50px;
    padding-right: 50px
  }
  .t-btn_xxl {
    font-size: 26px;
    padding-left: 60px;
    padding-right: 60px
  }
}

.t-submit {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  height: 60px;
  border: 0 none;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 60px;
  -webkit-appearance: none;
  font-weight: 700;
  white-space: nowrap;
  background-image: none;
  cursor: pointer;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  background: transparent
}

.t-submit_sending {
  opacity: .5
}

@media screen and (max-width:640px) {
  .t-submit {
    white-space: normal;
    padding-left: 30px;
    padding-right: 30px
  }
}

.t-input {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  height: 60px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 1.33;
  width: 100%;
  border: 0 none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none
}

.t-input::-moz-focus-inner {
  padding: 0;
  border: 0
}

.t-input_bbonly {
  outline: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid
}

.t-input_pvis {
  padding: 26px 20px 10px 20px
}

.t-input__vis-ph {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  opacity: .5;
  position: absolute;
  left: 20px;
  top: 22px;
  height: 16px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
  -webkit-transform-origin: left;
  transform-origin: left;
  pointer-events: none;
  white-space: nowrap
}

.t-input:focus~.t-input__vis-ph,
.t-input_has-content+.t-input__vis-ph {
  -webkit-transform: translateY(-12px) scale(.7);
  transform: translateY(-12px) scale(.7)
}

.t-form_bbonly .t-input__vis-ph {
  left: 0px !important
}

.t-form_bbonly .js-error-control-box .t-input__vis-ph {
  left: 10px !important
}

.t-select {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  height: 60px;
  padding: 0 45px 0 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.33;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none
}

.t-select_bbonly {
  outline: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important
}

.t-select_bbonly option {
  color: #000;
  background-color: #fff
}

.t-select__wrapper {
  position: relative
}

.t-select__wrapper:after {
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none
}

.t-select::-ms-expand {
  width: 0;
  height: 0;
  opacity: 0
}

.t-select__wrapper_bbonly::after {
  right: 0px !important
}

.t-checkbox__control {
  display: block;
  position: relative;
  cursor: pointer
}

.t-checkbox__control .t-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0
}

.t-checkbox__indicator {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  border: 2px solid #000;
  box-sizing: border-box;
  margin-right: 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  opacity: .6
}

.t-checkbox__indicator:after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg)
}

.t-checkbox__control .t-checkbox:checked~.t-checkbox__indicator {
  opacity: 1
}

.t-checkbox__control .t-checkbox:checked~.t-checkbox__indicator:after {
  display: block
}

.t-checkboxes__wrapper .t-checkbox__control {
  margin: 10px 0
}

.t-radio {
  position: absolute;
  z-index: -1;
  opacity: 0
}

.t-radio__control {
  display: table;
  position: relative;
  cursor: pointer;
  margin: 0 0 10px 0
}

.t-radio__control:first-child {
  margin: 3px 0 10px 0
}

.t-radio__control:last-child {
  margin: 0 0 0 0
}

.t-radio__indicator {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  border-color: #000;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  opacity: .6
}

.t-radio__indicator:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s
}

.t-radio__control .t-radio:checked~.t-radio__indicator:after {
  opacity: 1
}

.t-radio__control .t-radio:checked~.t-radio__indicator {
  opacity: 1
}

.t-inputquantity {
  width: 70px;
  text-align: center;
  margin-left: .5rem;
  margin-right: .5rem
}

.t-inputquantity__btn {
  font-size: 26px;
  cursor: pointer;
  margin-left: .5rem;
  margin-right: .5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none
}

.t-form .uploadcare-widget-button {
  background: #333;
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
  font-size: 14px
}

.t-form .uploadcare-widget-button:hover {
  background: #444
}

.t-input-title {
  display: block;
  vertical-align: middle;
  height: 100%;
  padding-right: 10px
}

.t-input-subtitle {
  display: block;
  vertical-align: middle;
  height: 100%;
  padding-right: 10px
}

.t-form__bottom-text {
  margin-top: 30px;
  text-align: center
}

.t-input-error {
  display: none;
  color: red;
  font-family: 'Roboto', serif;
  font-size: 13px;
  margin-top: 5px
}

.t-form .js-error-control-box .t-input-error {
  display: block
}

.t-form .js-error-control-box .t-input,
.t-form .js-error-control-box .t-checkbox__control,
.t-form .js-error-control-box .t-checkboxes__wrapper,
.t-form .js-error-control-box .t-select__wrapper,
.t-form .js-error-control-box .t-radio__wrapper,
.t-form .js-error-control-box .uploadcare-widget {
  border: 1px solid red !important
}

.t-form .js-error-control-box .t-checkboxes__wrapper,
.t-form .js-error-control-box .t-radio__wrapper,
.t-form .js-error-control-box .t-checkbox__control {
  padding: 10px
}

.t-form .js-error-control-box .t-checkboxes__wrapper .t-checkbox__control {
  border: none !important;
  padding: 0
}

.t-form .js-error-control-box .t-input.t-input_bbonly {
  padding-left: 10px !important
}

.t-form__errorbox-bottom {
  display: none
}

.t-form__errorbox-wrapper {
  background: #F95D51;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Roboto', serif
}

.t-form__errorbox-text {
  color: #fff;
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
  font-family: 'Roboto', serif
}

.t-form__errorbox-item {
  padding-top: 10px;
  display: none;
  font-family: 'Roboto', serif
}

.t-form__successbox {
  text-align: center;
  background: #62C584;
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  font-family: 'Roboto', serif
}

@keyframes t-submit-spinner {
  to {
    transform: rotate(360deg)
  }
}

.t-form .t-submit {
  position: relative
}

.t-form .t-submit.t-btn_sending {
  color: transparent !important
}

.t-form .t-btn_sending:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: t-submit-spinner .6s linear infinite
}

.t-btntext {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 20px;
  line-height: 1.55;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  display: inline-block
}

.t-btntext_sm {
  font-size: 16px
}

.t-btntext_lg {
  font-size: 24px
}

.t-uppercase.t-btntext {
  font-size: 16px
}

.t-uppercase.t-btntext_sm {
  font-size: 14px
}

.t-uppercase.t-btntext_lg {
  font-size: 20px
}

.t-btntext:after {
  content: "\00a0→";
  font-family: Arial, Helvetica, sans-serif
}

@media screen and (max-width:640px) {
  .t-btntext_lg {
    font-size: 20px
  }
}

@media screen and (max-width:1200px) {
  .t-screenmin-1200px {
    display: none
  }
}

@media screen and (max-width:980px) {
  .t-screenmin-980px {
    display: none
  }
}

@media screen and (max-width:640px) {
  .t-screenmin-640px {
    display: none
  }
}

@media screen and (max-width:480px) {
  .t-screenmin-480px {
    display: none
  }
}

@media screen and (max-width:320px) {
  .t-screenmin-320px {
    display: none
  }
}

@media screen and (min-width:321px) {
  .t-screenmax-320px {
    display: none
  }
}

@media screen and (min-width:481px) {
  .t-screenmax-480px {
    display: none
  }
}

@media screen and (min-width:641px) {
  .t-screenmax-640px {
    display: none
  }
}

@media screen and (min-width:981px) {
  .t-screenmax-980px {
    display: none
  }
}

@media screen and (min-width:1201px) {
  .t-screenmax-1200px {
    display: none
  }
}

.t-hidden {
  display: none
}

.t-opacity_50 {
  filter: alpha(opacity=50);
  KHTMLOpacity: .5;
  MozOpacity: .5;
  opacity: .5
}

.t-opacity_70 {
  filter: alpha(opacity=70);
  KHTMLOpacity: .7;
  MozOpacity: .7;
  opacity: .7
}

.t-uppercase {
  text-transform: uppercase
}

.t-align_center {
  text-align: center
}

.t-align_left {
  text-align: left
}

.t-align_right {
  text-align: right
}

.t-margin_auto {
  margin-left: auto;
  margin-right: auto
}

.t-valign_middle {
  vertical-align: middle
}

.t-valign_top {
  vertical-align: top
}

.t-valign_bottom {
  vertical-align: bottom
}

.t-margin_left_auto {
  margin-right: 0;
  margin-left: auto
}

.yashare-style .b-share-btn__facebook,
.yashare-style .b-share-btn__twitter,
.yashare-style .b-share-btn__vkontakte {
  background-color: transparent !important
}

.yashare-style .b-share__link {
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important
}

.yashare-style-black-white .b-share-btn__wrap {
  background-color: #000 !important;
  padding: 5px !important
}

.yashare-style-transp-white .b-share-btn__wrap {
  padding: 5px !important
}

.yashare-style-transp-white .b-share-counter {
  color: #fff;
  font-weight: 700
}

.yashare-style-white-black .b-share-btn__wrap {
  background-color: #fff !important;
  padding: 5px !important
}

.yashare-style-white-black .b-share-icon {
  background-image: url(//static.tildacdn.com/img/b-share_counter_large_white.png) !important
}

.yashare-style-transp-black .b-share-btn__wrap {
  padding: 5px !important
}

.yashare-style-transp-black .b-share-icon {
  background-image: url(//static.tildacdn.com/img/b-share_counter_large_white.png) !important
}

.yashare-style-transp-black .b-share-counter {
  color: #000;
  font-weight: 700
}

.ya-share2 ul {
  padding-left: 0px !important
}

.carousel {
  position: relative
}

.carousel ol {
  padding-left: 0px !important
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner>.item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  display: block;
  height: auto;
  line-height: 1
}

.carousel-inner .widthauto {
  width: auto;
  max-width: 100%;
  vertical-align: middle
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
  display: block
}

.carousel-inner>.active {
  left: 0
}

.carousel-inner>.next,
.carousel-inner>.prev {
  position: absolute;
  top: 0;
  width: 100%
}

.carousel-inner>.next {
  left: 100%
}

.carousel-inner>.prev {
  left: -100%
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
  left: 0
}

.carousel-inner>.active.left {
  left: -100%
}

.carousel-inner>.active.right {
  left: 100%
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  opacity: .2;
  filter: alpha(opacity=20)
}

.carousel-control.right {
  right: 0;
  left: auto
}

.carousel-control .carousel-control-left {
  position: absolute;
  top: 48%;
  z-index: 5;
  display: inline-block;
  left: 20%;
  height: 34px;
  width: 21px;
  background: url(//static.tildacdn.com/img/aboutSliderControls.png) no-repeat
}

.carousel-control .carousel-control-left-white {
  position: absolute;
  top: 48%;
  z-index: 5;
  display: inline-block;
  left: 20%;
  height: 34px;
  width: 21px;
  background: url(//static.tildacdn.com/img/aboutSliderControls_white.png) no-repeat
}

.carousel-control .carousel-control-right {
  position: absolute;
  top: 48%;
  z-index: 5;
  display: inline-block;
  right: 20%;
  height: 34px;
  width: 21px;
  background: url(//static.tildacdn.com/img/aboutSliderControls.png) no-repeat;
  background-position: left bottom
}

.carousel-control .carousel-control-right-white {
  position: absolute;
  top: 48%;
  z-index: 5;
  display: inline-block;
  right: 20%;
  height: 34px;
  width: 21px;
  background: url(//static.tildacdn.com/img/aboutSliderControls_white.png) no-repeat;
  background-position: left bottom
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none
}

.carousel-indicators.dotsbottom {
  bottom: -60px
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  margin-left: 5px;
  margin-right: 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  border: none;
  border-radius: 10px
}

.carousel-indicators .active {
  width: 10px;
  height: 10px;
  margin: 0;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: transparent
}

.carousel-indicators li.white {
  background-color: #fff
}

.carousel-indicators li.white.active {
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: transparent
}

.carousel-caption-imgs h6 {
  font-family: 'Roboto', serif;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  padding-top: 28px;
  padding-bottom: 0;
  text-align: center
}

.carousel-caption-imgs p {
  font-family: 'Roboto', serif;
  color: #000;
  font-size: 14px;
  line-height: 28px;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center
}

.carousel-title {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 28px;
  padding-top: 36px;
  padding-bottom: 14px;
  text-align: center
}

.carousel-descr {
  font-family: 'Roboto', serif;
  color: #000;
  font-size: 14px;
  line-height: 28px;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center
}

@media screen and (min-width:768px) {
  .carousel-indicators {
    bottom: 20px
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " "
}

.clearfix:after {
  clear: both
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto
}

@media screen and (max-width:960px) {
  .carousel-control .carousel-control-left {
    left: 10%
  }
  .carousel-control .carousel-control-left-white {
    left: 10%
  }
  .carousel-control .carousel-control-right {
    right: 10%
  }
  .carousel-control .carousel-control-right-white {
    right: 10%
  }
}

.t-tildalabel {
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 70px;
  font-family: Arial;
  font-size: 14px
}

.t-tildalabel:hover .t-tildalabel__wrapper {
  opacity: 1
}

.t-tildalabel_white {
  background-color: #fff;
  color: #000
}

.t-tildalabel_gray {
  background-color: #eee;
  color: #000
}

.t-tildalabel__wrapper {
  display: table;
  height: 30px;
  width: 270px;
  margin: 0 auto;
  padding-top: 20px;
  opacity: .4
}

.t-tildalabel__txtleft {
  display: table-cell;
  width: 120px;
  height: 30px;
  vertical-align: middle;
  text-align: right;
  padding-right: 12px;
  font-weight: 300;
  font-size: 12px
}

.t-tildalabel__wrapimg {
  display: table-cell;
  width: 30px;
  height: 30px;
  vertical-align: middle
}

.t-tildalabel__img {
  width: 30px;
  height: 30px;
  vertical-align: middle
}

.t-tildalabel__txtright {
  display: table-cell;
  width: 120px;
  height: 30px;
  vertical-align: middle;
  padding-left: 12px;
  font-weight: 500;
  letter-spacing: 2px
}

.t-tildalabel__link {
  color: #fff;
  text-decoration: none;
  vertical-align: middle
}

.t-tildalabel_white .t-tildalabel__link,
.t-tildalabel_gray .t-tildalabel__link {
  color: #000
}

.t-tildalabel-free {
  padding: 35px 0 45px;
  height: auto;
  text-align: center
}

.t-tildalabel-free__main {
  max-width: 340px;
  display: block;
  margin: 0 auto;
  line-height: 1.4;
  letter-spacing: .2px;
  color: inherit;
  opacity: .4
}

.t-tildalabel-free__links-wr {
  display: table;
  margin: 30px auto 0
}

.t-tildalabel-free__txt-link {
  display: table-cell;
  padding: 0 15px;
  font-size: 13px;
  text-decoration: none;
  color: inherit;
  opacity: .25
}

.t-tildalabel-free__txt-link:hover,
.t-tildalabel-free__main:hover {
  opacity: 1
}

@media screen and (max-width:640px) {
  .t-tildalabel-free__txt-link {
    display: block;
    padding: 5px 30px
  }
  .t-tildalabel-free__main {
    opacity: .7
  }
  .t-tildalabel-free__txt-link {
    opacity: .4
  }
}

.t-carousel {
  position: relative
}

.t-carousel__inner {
  position: relative;
  overflow: hidden;
  margin: 0 auto
}

.t-carousel__slides {
  position: relative
}

.t-carousel__inner>.t-carousel__item {
  position: relative;
  display: none;
  -webkit-transition: 0 ease-in-out left;
  -moz-transition: 0 ease-in-out left;
  -o-transition: 0 ease-in-out left;
  transition: 0 ease-in-out left
}

.t-carousel__inner>.t-carousel__item.t-carousel__animation_fast {
  -webkit-transition: .3s ease-in-out left;
  -moz-transition: .3s ease-in-out left;
  -o-transition: .3s ease-in-out left;
  transition: .3s ease-in-out left
}

.t-carousel__inner>.t-carousel__item.t-carousel__animation_slow {
  -webkit-transition: .6s ease-in-out left;
  -moz-transition: .6s ease-in-out left;
  -o-transition: .6s ease-in-out left;
  transition: .6s ease-in-out left
}

.t-carousel__item__wrapper {
  position: relative;
  margin: 0 auto
}

.t-carousel__item__img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.t-carousel_cover .t-carousel__item__img {
  background-size: cover
}

.t-carousel__inner>.active,
.t-carousel__inner>.next,
.t-carousel__inner>.prev {
  display: block
}

.t-carousel__inner>.active {
  left: 0
}

.t-carousel__inner>.next,
.t-carousel__inner>.prev {
  position: absolute;
  top: 0;
  width: 100%
}

.t-carousel__inner>.next {
  left: 100%
}

.t-carousel__inner>.prev {
  left: -100%
}

.t-carousel__inner>.next.left,
.t-carousel__inner>.prev.right {
  left: 0
}

.t-carousel__inner>.active.left {
  left: -100%
}

.t-carousel__inner>.active.right {
  left: 100%
}

.t-carousel__arrows__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  pointer-events: none;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=', sizingMethod='scale');
  background: none !important
}

.t-carousel__arrow_outsidesmall .t-carousel__arrow__wrapper_left {
  left: 16px
}

.t-carousel__arrow_outsidesmall .t-carousel__arrow__wrapper_right {
  right: 16px
}

.t-carousel__arrow_outsidemiddle .t-carousel__arrow__wrapper_left {
  left: 20px
}

.t-carousel__arrow_outsidemiddle .t-carousel__arrow__wrapper_right {
  right: 20px
}

.t-carousel__control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  pointer-events: auto
}

.t-carousel__control:hover {
  opacity: .6
}

.t-carousel__arrow {
  width: 34px;
  height: 34px;
  background: transparent;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg)
}

.t-carousel__arrow.t-carousel__arrow_small {
  width: 20px;
  height: 20px
}

.t-carousel__arrow.t-carousel__arrow_large {
  width: 54px;
  height: 54px
}

.t-carousel__arrow__wrapper {
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%
}

.t-carousel__arrow__wrapper_left {
  left: 30px
}

.t-carousel__arrow__wrapper_right {
  right: 30px
}

.t-carousel__arrow_right {
  border-top: 3px solid;
  border-right: 3px solid
}

.t-carousel__arrow_right.t-carousel__arrow_light {
  border-top: 1px solid;
  border-right: 1px solid
}

.t-carousel__arrow_right.t-carousel__arrow_bold {
  border-top: 6px solid;
  border-right: 6px solid
}

.t-carousel__arrow_left {
  border-left: 3px solid;
  border-bottom: 3px solid
}

.t-carousel__arrow_left.t-carousel__arrow_light {
  border-left: 1px solid;
  border-bottom: 1px solid
}

.t-carousel__arrow_left.t-carousel__arrow_bold {
  border-left: 6px solid;
  border-bottom: 6px solid
}

.t-carousel__control.right {
  right: 0;
  left: auto
}

@media screen and (max-width:768px) {
  .t-carousel__control .t-carousel__arrow {
    width: 12px;
    height: 12px
  }
  .t-carousel-control {
    width: 10%
  }
  .t-carousel__arrow__left {
    left: 15px
  }
  .t-carousel__arrow__right {
    right: 15px
  }
}

.t-carousel__indicators.carousel-indicators {
  z-index: 15;
  text-align: center;
  list-style: none;
  position: relative;
  padding-left: 0 !important;
  margin: 0 auto;
  padding: 20px 0;
  bottom: auto;
  left: auto
}

.t-carousel__indicators.t-carousel__indicators_light {
  padding: 15px 0 18px
}

.t-carousel__indicators.t-carousel__indicators_bold {
  padding: 24px 0 21px
}

.t-carousel__indicators .t-carousel__indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 6px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #222;
  border: none;
  border-radius: 10px;
  opacity: .4;
  -webkit-transition: .2s ease-in-out opacity;
  -moz-transition: .2s ease-in-out opacity;
  -o-transition: .2s ease-in-out opacity;
  transition: .2s ease-in-out opacity
}

@media screen and (max-width:640px) {
  .t-carousel__indicators.carousel-indicators,
  .t-carousel__indicators.t-carousel__indicators_light,
  .t-carousel__indicators.t-carousel__indicators_bold {
    padding: 15px 0
  }
}

.t-carousel__indicators.t-carousel__indicators_light .t-carousel__indicator {
  width: 4px;
  height: 4px;
  margin: 0 5px
}

.t-carousel__indicators.t-carousel__indicators_bold .t-carousel__indicator {
  width: 10px;
  height: 10px;
  margin: 0 6px
}

.t-carousel__indicators .t-carousel__indicator:hover {
  opacity: .8
}

.t-carousel__indicators .t-carousel__indicator.active {
  opacity: 1
}

.t-carousel__indicators.t-carousel__indicators_inside {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0
}

.t-carousel__caption-inside {
  display: none
}

.t-carousel__caption_wrapper {
  border-top: 1px solid #eee;
  padding: 14px 0
}

.t-carousel__descr {
  margin-top: 5px;
  color: #777
}

.t-mbfix {
  opacity: .01;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  position: fixed;
  width: 100%;
  height: 500px;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 10000;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease
}

.t-mbfix_hide {
  -webkit-transform: translateX(3000px);
  -ms-transform: translateX(3000px);
  transform: translateX(3000px)
}

.r_anim {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s
}

.r_hidden {
  opacity: 0
}

.r_showed {
  opacity: 1
}

img:not([src]) {
  visibility: hidden
}

.t-rec_pt_0 {
  padding-top: 0
}

.t-rec_pt_15 {
  padding-top: 15px
}

.t-rec_pt_30 {
  padding-top: 30px
}

.t-rec_pt_45 {
  padding-top: 45px
}

.t-rec_pt_60 {
  padding-top: 60px
}

.t-rec_pt_75 {
  padding-top: 75px
}

.t-rec_pt_90 {
  padding-top: 90px
}

.t-rec_pt_105 {
  padding-top: 105px
}

.t-rec_pt_120 {
  padding-top: 120px
}

.t-rec_pt_135 {
  padding-top: 135px
}

.t-rec_pt_150 {
  padding-top: 150px
}

.t-rec_pt_165 {
  padding-top: 165px
}

.t-rec_pt_180 {
  padding-top: 180px
}

.t-rec_pt_195 {
  padding-top: 195px
}

.t-rec_pt_210 {
  padding-top: 210px
}

.t-rec_pb_0 {
  padding-bottom: 0
}

.t-rec_pb_15 {
  padding-bottom: 15px
}

.t-rec_pb_30 {
  padding-bottom: 30px
}

.t-rec_pb_45 {
  padding-bottom: 45px
}

.t-rec_pb_60 {
  padding-bottom: 60px
}

.t-rec_pb_75 {
  padding-bottom: 75px
}

.t-rec_pb_90 {
  padding-bottom: 90px
}

.t-rec_pb_105 {
  padding-bottom: 105px
}

.t-rec_pb_120 {
  padding-bottom: 120px
}

.t-rec_pb_135 {
  padding-bottom: 135px
}

.t-rec_pb_150 {
  padding-bottom: 150px
}

.t-rec_pb_165 {
  padding-bottom: 165px
}

.t-rec_pb_180 {
  padding-bottom: 180px
}

.t-rec_pb_195 {
  padding-bottom: 195px
}

.t-rec_pb_210 {
  padding-bottom: 210px
}

@media screen and (max-width:480px) {
  .t-rec_pt_0 {
    padding-top: 0
  }
  .t-rec_pt_15 {
    padding-top: 15px
  }
  .t-rec_pt_30 {
    padding-top: 30px
  }
  .t-rec_pt_45 {
    padding-top: 45px
  }
  .t-rec_pt_60 {
    padding-top: 60px
  }
  .t-rec_pt_75 {
    padding-top: 75px
  }
  .t-rec_pt_90 {
    padding-top: 90px
  }
  .t-rec_pt_105 {
    padding-top: 90px
  }
  .t-rec_pt_120 {
    padding-top: 90px !important
  }
  .t-rec_pt_135 {
    padding-top: 90px !important
  }
  .t-rec_pt_150 {
    padding-top: 90px !important
  }
  .t-rec_pt_165 {
    padding-top: 90px !important
  }
  .t-rec_pt_180 {
    padding-top: 120px !important
  }
  .t-rec_pt_195 {
    padding-top: 120px !important
  }
  .t-rec_pt_210 {
    padding-top: 120px !important
  }
  .t-rec_pb_0 {
    padding-bottom: 0
  }
  .t-rec_pb_15 {
    padding-bottom: 15px
  }
  .t-rec_pb_30 {
    padding-bottom: 30px
  }
  .t-rec_pb_45 {
    padding-bottom: 45px
  }
  .t-rec_pb_60 {
    padding-bottom: 60px
  }
  .t-rec_pb_75 {
    padding-bottom: 75px
  }
  .t-rec_pb_90 {
    padding-bottom: 90px
  }
  .t-rec_pb_105 {
    padding-bottom: 90px
  }
  .t-rec_pb_120 {
    padding-bottom: 90px !important
  }
  .t-rec_pb_135 {
    padding-bottom: 90px !important
  }
  .t-rec_pb_150 {
    padding-bottom: 90px !important
  }
  .t-rec_pb_165 {
    padding-bottom: 90px !important
  }
  .t-rec_pb_180 {
    padding-bottom: 120px !important
  }
  .t-rec_pb_195 {
    padding-bottom: 120px !important
  }
  .t-rec_pb_210 {
    padding-bottom: 120px !important
  }
}

@media screen and (max-width:480px) {
  .t-rec_pt-res-480_0 {
    padding-top: 0 !important
  }
  .t-rec_pt-res-480_15 {
    padding-top: 15px !important
  }
  .t-rec_pt-res-480_30 {
    padding-top: 30px !important
  }
  .t-rec_pt-res-480_45 {
    padding-top: 45px !important
  }
  .t-rec_pt-res-480_60 {
    padding-top: 60px !important
  }
  .t-rec_pt-res-480_75 {
    padding-top: 75px !important
  }
  .t-rec_pt-res-480_90 {
    padding-top: 90px !important
  }
  .t-rec_pt-res-480_105 {
    padding-top: 105px !important
  }
  .t-rec_pt-res-480_120 {
    padding-top: 120px !important
  }
  .t-rec_pt-res-480_135 {
    padding-top: 135px !important
  }
  .t-rec_pt-res-480_150 {
    padding-top: 150px !important
  }
  .t-rec_pt-res-480_165 {
    padding-top: 165px !important
  }
  .t-rec_pt-res-480_180 {
    padding-top: 180px !important
  }
  .t-rec_pt-res-480_195 {
    padding-top: 195px !important
  }
  .t-rec_pt-res-480_210 {
    padding-top: 210px !important
  }
  .t-rec_pb-res-480_0 {
    padding-bottom: 0 !important
  }
  .t-rec_pb-res-480_15 {
    padding-bottom: 15px !important
  }
  .t-rec_pb-res-480_30 {
    padding-bottom: 30px !important
  }
  .t-rec_pb-res-480_45 {
    padding-bottom: 45px !important
  }
  .t-rec_pb-res-480_60 {
    padding-bottom: 60px !important
  }
  .t-rec_pb-res-480_75 {
    padding-bottom: 75px !important
  }
  .t-rec_pb-res-480_90 {
    padding-bottom: 90px !important
  }
  .t-rec_pb-res-480_105 {
    padding-bottom: 105px !important
  }
  .t-rec_pb-res-480_120 {
    padding-bottom: 120px !important
  }
  .t-rec_pb-res-480_135 {
    padding-bottom: 135px !important
  }
  .t-rec_pb-res-480_150 {
    padding-bottom: 150px !important
  }
  .t-rec_pb-res-480_165 {
    padding-bottom: 165px !important
  }
  .t-rec_pb-res-480_180 {
    padding-bottom: 180px !important
  }
  .t-rec_pb-res-480_195 {
    padding-bottom: 195px !important
  }
  .t-rec_pb-res-480_210 {
    padding-bottom: 210px !important
  }
}

.t-body_scroll-locked {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.t107 {
  text-align: center
}

.t107__width {
  vertical-align: middle
}

.t107__widthauto {
  width: auto;
  max-width: 100%;
  display: block;
  margin: 0 auto
}

.t107__title {
  padding-top: 28px;
  padding-bottom: 28px;
  font-size: 14px;
  line-height: 28px
}

.t030__title {
  margin-bottom: 15px
}

.t030__descr {
  margin-top: 8px;
  padding-bottom: 6px
}

.t183__wrapper {
  padding-top: 42px;
  padding-bottom: 42px
}

.t183__uptitle {
  color: #fff;
  padding-bottom: 20px;
  padding-top: 10px
}

.t183__title {
  color: #fff;
  padding: 24px 0 24px 0;
  letter-spacing: 1px
}

.t183__buttons {
  margin-top: 45px
}

.t183 .t-btn:nth-child(2) {
  margin-left: 10px
}

@media screen and (max-width:640px) {
  .t183__title {
    padding-left: 10px;
    padding-right: 10px
  }
  .t183__uptitle {
    padding-left: 10px;
    padding-right: 10px
  }
  .t183 .t-btn:nth-child(2) {
    margin-left: 5px
  }
  .t183 .t-btn {
    margin: 5px
  }
}

.t208__wrapper {
  display: table;
  padding-top: 120px;
  padding-bottom: 120px
}

.t208__textwrapper {
  display: block;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0
}

.t208__text {
  padding-bottom: 20px;
  padding-top: 20px;
  color: #fff
}

@media screen and (max-width:720px) {
  .t208__textwrapper {
    margin: 20px 0;
    padding: 20px
  }
}

.t228 {
  width: 100%;
  height: 80px;
  z-index: 990;
  position: fixed;
  top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear
}

.t228__beforeready {
  visibility: hidden
}

.t228 ul {
  margin: 0
}

.t228__maincontainer {
  width: 100%;
  height: 80px;
  display: table
}

.t228__maincontainer.t228__c12collumns {
  max-width: 1200px;
  margin: 0 auto
}

.t228__leftside {
  display: table-cell;
  vertical-align: middle
}

.t228__centerside {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%
}

.t228__centerside.t228__menualign_left {
  text-align: left
}

.t228__centerside.t228__menualign_right {
  text-align: right
}

.t228__rightside {
  display: table-cell;
  vertical-align: middle;
  text-align: right
}

.t228 .lastside {
  display: table-cell;
  vertical-align: middle
}

.t228__padding40px {
  width: 40px
}

.t228__maincontainer.t228__c12collumns .t228__padding40px {
  width: 20px
}

.t228__logo {
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  white-space: nowrap;
  padding-right: 30px
}

.t228__imglogo {
  margin-right: 30px
}

.t228__list {
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
  -webkit-transition: opacity ease-in-out .2s;
  -moz-transition: opacity ease-in-out .2s;
  -o-transition: opacity ease-in-out .2s;
  transition: opacity ease-in-out .2s
}

.t228__list_item {
  clear: both;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  display: inline;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  color: #000;
  white-space: nowrap
}

.t228__list_item .t-active {
  opacity: .7
}

.t228__list_item:first-child {
  padding-left: 0
}

.t228__list_item:last-child {
  padding-right: 0
}

.t228__leftcontainer {
  display: inline-block
}

.t228__rightcontainer {
  display: table;
  text-align: right;
  width: auto;
  position: relative;
  float: right
}

.t228__right_descr {
  padding-left: 30px;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #000
}

.t228__right_buttons {
  padding-left: 30px;
  display: table-cell;
  vertical-align: middle
}

.t228__right_buttons_wrap {
  display: table;
  width: auto;
  float: right
}

.t228__right_buttons_but {
  display: table-cell;
  vertical-align: middle
}

.t228__right_share_buttons {
  display: table-cell;
  vertical-align: middle;
  min-width: 220px
}

.t228 .b-share {
  display: table !important;
  float: right
}

.t228 .b-share-btn__wrap {
  display: table-cell !important;
  float: none !important;
  padding-left: 5px !important
}

.t228__right_langs {
  padding-left: 30px;
  display: table-cell;
  vertical-align: middle
}

.t228__right_langs_wrap {
  display: table;
  width: auto;
  float: right
}

.t228__right_langs_lang {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000;
  word-break: keep-all
}

.t228__right_social_links {
  padding-left: 30px;
  display: table-cell;
  vertical-align: middle;
  line-height: 0
}

.t228__right_social_links_wrap {
  display: table;
  width: auto
}

.t228__right_social_links a {
  display: block;
  vertical-align: middle
}

.t228__right_social_links_item {
  display: table-cell;
  vertical-align: middle;
  padding: 0 5px
}

.t228__mobile {
  display: none
}

.t228 a,
#allrecords .t228 a {
  text-decoration: none;
  color: #000
}

.t228 .t-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 10px
}

.t228.t228__positionabsolute {
  position: absolute
}

.t228.t228__positionfixed {
  position: fixed
}

.t228.t228__positionstatic {
  position: static
}

@media screen and (max-width:1200px) {
  .t228__padding40px {
    width: 20px
  }
  .t228__maincontainer.t228__c12collumns .t228__padding40px {
    width: 20px
  }
  .t228__imglogo {
    margin-right: 15px
  }
  .t228__logo {
    padding-right: 15px
  }
  .t228__right_descr {
    padding-left: 15px
  }
  .t228__right_buttons {
    padding-left: 15px
  }
  .t228__right_share_buttons {
    padding-left: 15px
  }
  .t228__right_langs {
    padding-left: 15px
  }
  .t228__right_social_links {
    padding-left: 15px
  }
}

@media screen and (max-width:980px) {
  .t228__list_hidden {
    opacity: 1
  }
  .t228 {
    position: static;
    text-align: center;
    display: block;
    margin: 0;
    padding: 0;
    height: auto !important
  }
  .t228.t228__positionabsolute,
  .t228.t228__positionfixed,
  .t228.t228__positionstatic {
    position: static
  }
  .t228__leftside,
  .t228__rightside,
  .t228__centerside {
    display: block;
    text-align: center;
    float: none
  }
  .t228__leftcontainer,
  .t228__centercontainer,
  .t228__rightcontainer {
    padding: 20px 0
  }
  .t228__imglogo {
    width: auto !important;
    box-sizing: border-box;
    padding: 20px;
    margin: 0 auto
  }
  .t228__imglogomobile.t228__imglogo {
    width: 100% !important
  }
  .t228__padding40px {
    display: none
  }
  .t228__padding10px {
    display: none
  }
  .t228__rightcontainer {
    display: table;
    position: static;
    float: none;
    text-align: center;
    margin: 0 auto
  }
  .t228__right_share_buttons {
    display: table;
    position: static;
    padding: 15px 0;
    margin: 0 auto;
    text-align: center;
    float: none;
    min-width: inherit
  }
  .t228__right_buttons {
    display: table;
    padding: 15px 0;
    margin: 0 auto;
    text-align: center
  }
  .t228__right_descr,
  .t228__right_langs,
  .t228__right_social_links {
    display: table;
    padding: 10px 0;
    margin: 0 auto;
    text-align: center
  }
  .t228__logo {
    text-align: center;
    margin: 20px
  }
  .t228 img {
    float: inherit
  }
  .t228 .t228__list_item {
    display: block;
    text-align: center;
    padding: 10px !important;
    white-space: normal
  }
  .t228__list_item a {
    display: inline-block
  }
  .t228 .t-btn {
    margin-left: 5px
  }
  .t228__logo {
    white-space: normal;
    padding: 0
  }
  .t228.t228__hidden {
    display: none;
    height: 100%
  }
  .t228__mobile {
    background-color: #111;
    display: block
  }
  .t228__mobile_text {
    color: #fff
  }
  .t228__mobile_container {
    padding: 20px;
    position: relative
  }
  .t228__burger {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 20px;
    width: 28px;
    height: 20px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 9999
  }
  .t228__burger span {
    display: block;
    position: absolute;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    height: 3px;
    background-color: #fff
  }
  .t228__burger span:nth-child(1) {
    top: 0
  }
  .t228__burger span:nth-child(2),
  .t228__burger span:nth-child(3) {
    top: 8px
  }
  .t228__burger span:nth-child(4) {
    top: 16px
  }
  .t228_opened .t228__burger span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%
  }
  .t228_opened .t228__burger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
  }
  .t228_opened .t228__burger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
  .t228_opened .t228__burger span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%
  }
}

@media screen and (max-width:640px) {
  .t228__right_buttons_but {
    display: table;
    padding: 0 0;
    margin: 0 auto 10px
  }
}

.t228 .ya-share2__container_size_m .ya-share2__icon {
  width: 28px;
  height: 28px
}

.t228 .ya-share2__list {
  margin-bottom: 0
}

.t228 .ya-share2__container_size_m .ya-share2__counter {
  line-height: 28px
}

.t228__black-white .ya-share2__badge {
  background-color: #111 !important
}

.t228__transp-white .ya-share2__badge {
  background-color: transparent !important
}

.t228__transp-white .ya-share2__container_size_m .ya-share2__counter:before {
  display: none
}

.t228__transp-white .ya-share2__container_size_m .ya-share2__counter {
  padding-left: 0 !important;
  margin-top: 2px
}

.t228__white-black .ya-share2__badge {
  background-color: #fff !important
}

.t228__white-black .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjEgMjN2LTguMjFoMi43NzNsLjQxNS0zLjJIMTUuMVY5LjU0N2MwLS45MjcuMjYtMS41NTggMS41OTYtMS41NThsMS43MDQtLjAwMlY1LjEyNkEyMi43ODcgMjIuNzg3IDAgMCAwIDE1LjkxNyA1QzEzLjQ2IDUgMTEuNzggNi40OTIgMTEuNzggOS4yM3YyLjM2SDl2My4yaDIuNzhWMjNoMy4zMnoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==)
}

.t228__white-black .ya-share2__container_size_m .ya-share2__counter {
  color: #222
}

.t228__white-black .ya-share2__counter:before {
  background-color: #222
}

.t228__white-black .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0Ljg4MyAxOS4zOTZzLjMyNS0uMDM2LjQ5LS4yMThjLjE1NC0uMTY3LjE1LS40OC4xNS0uNDhzLS4wMjMtMS40NjguNjQ4LTEuNjg0Yy42Ni0uMjEzIDEuNTEgMS40MTggMi40MDggMi4wNDYuNjguNDc0IDEuMTk3LjM3IDEuMTk3LjM3bDIuNDA0LS4wMzRzMS4yNTYtLjA4LjY2LTEuMDg0Yy0uMDUtLjA4Mi0uMzQ4LS43NDMtMS43ODgtMi4xMDItMS41MDctMS40MjMtMS4zMDUtMS4xOTIuNTEtMy42NTMgMS4xMDYtMS40OTggMS41NDgtMi40MTIgMS40MS0yLjgwNC0uMTMyLS4zNzMtLjk0NS0uMjc1LS45NDUtLjI3NWwtMi43MDYuMDE3cy0uMi0uMDI4LS4zNS4wNjNjLS4xNDQuMDg4LS4yMzguMjk1LS4yMzguMjk1cy0uNDI4IDEuMTYtMSAyLjE0NmMtMS4yMDQgMi4wOC0xLjY4NiAyLjE5LTEuODgzIDIuMDYtLjQ2LS4zLS4zNDUtMS4yMS0uMzQ1LTEuODU1IDAtMi4wMTcuMy0yLjg1Ny0uNTg2LTMuMDc1LS4yOTUtLjA3Mi0uNTEyLS4xMi0xLjI2NC0uMTI4LS45NjYtLjAxLTEuNzgzLjAwMy0yLjI0Ni4yMzQtLjMwOC4xNTMtLjU0Ni40OTUtLjQuNTE0LjE3OC4wMjUuNTgzLjExLjc5OC40MS4yNzcuMzgyLjI2OCAxLjI0NC4yNjggMS4yNDRzLjE2IDIuMzczLS4zNzMgMi42NjhjLS4zNjUuMjAyLS44NjUtLjIxLTEuOTQtMi4wOTgtLjU1LS45NjctLjk2Ni0yLjAzNi0uOTY2LTIuMDM2cy0uMDgtLjItLjIyMy0uMzA2Yy0uMTczLS4xMy0uNDE2LS4xNy0uNDE2LS4xN2wtMi41Ny4wMTZzLS4zODguMDEtLjUzLjE4MmMtLjEyNS4xNTItLjAxLjQ2Ni0uMDEuNDY2czIuMDE0IDQuNzkgNC4yOTQgNy4yMDJjMi4wOSAyLjIxNCA0LjQ2NSAyLjA2OCA0LjQ2NSAyLjA2OGgxLjA3NnoiICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=)
}

.t228__white-black .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIzIDguNzNhNy4zOCA3LjM4IDAgMCAxLTIuMTIuNTgzIDMuNzA2IDMuNzA2IDAgMCAwIDEuNjIzLTIuMDQzIDcuMzk3IDcuMzk3IDAgMCAxLTIuMzQ2Ljg5NiAzLjY5MyAzLjY5MyAwIDAgMC02LjI5MyAzLjM2OCAxMC40ODUgMTAuNDg1IDAgMCAxLTcuNjEtMy44NThjLS4zMi41NDUtLjUgMS4xOC0uNSAxLjg1NiAwIDEuMjguNjUgMi40MSAxLjY0MiAzLjA3M2EzLjY4MyAzLjY4MyAwIDAgMS0xLjY3My0uNDYydi4wNDdjMCAxLjc4OCAxLjI3MyAzLjI4IDIuOTYyIDMuNjJhMy43MTggMy43MTggMCAwIDEtMS42NjcuMDYzIDMuNjk3IDMuNjk3IDAgMCAwIDMuNDUgMi41NjRBNy40MSA3LjQxIDAgMCAxIDUgMTkuOTY3YTEwLjQ1MyAxMC40NTMgMCAwIDAgNS42NiAxLjY1OGM2Ljc5NCAwIDEwLjUwOC01LjYyNiAxMC41MDgtMTAuNTA1IDAtLjE2LS4wMDMtLjMyLS4wMS0uNDc4QTcuNTA3IDcuNTA3IDAgMCAwIDIzIDguNzMyeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
}

.t228__white-black .ya-share2__container_size_m .ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNTBweCIgaGVpZ2h0PSIxNTBweCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1MCAxNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjx0aXRsZT5TaGFwZTwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+PGcgaWQ9IldlbGNvbWUiPjxwYXRoIGlkPSJTaGFwZSIgZmlsbD0iIzIyMjIyMiIgZD0iTTc0Ljk5Nyw0Ni4wMTFjLTQuOTAzLDAuMDA2LTguODc1LDMuOTc5LTguODgzLDguODgzYzAsNC44OTQsMy45ODUsOC44NzksOC44ODMsOC44NzljNC45MDMtMC4wMDgsOC44NzUtMy45NzksOC44OC04Ljg3OUM4My44NzksNDkuOTg5LDc5LjksNDYuMDE3LDc0Ljk5Nyw0Ni4wMTFMNzQuOTk3LDQ2LjAxMXogTTc0Ljk5Nyw3Ni4zMzhjLTExLjgzOC0wLjAxLTIxLjQzNy05LjYwNi0yMS40NDgtMjEuNDQ0YzAuMDA4LTExLjg0Niw5LjYwNC0yMS40NDIsMjEuNDQ4LTIxLjQ1NWMxMS44NDUsMC4wMSwyMS40NTEsOS42MDksMjEuNDU2LDIxLjQ1NUM5Ni40MzgsNjYuNzM0LDg2LjgzOCw3Ni4zMyw3NC45OTcsNzYuMzM4TDc0Ljk5Nyw3Ni4zMzh6IE02Ni4zMiw5My44MzZjLTQuNDEyLTEuMDAyLTguNjI0LTIuNzQ4LTEyLjQ1NS01LjE1NGMtMi45MzktMS44NS0zLjgyMy01LjczNC0xLjk3My04LjY2OGMxLjg0OC0yLjk0MSw1LjcyOC0zLjgyMiw4LjY2Ni0xLjk3NWM4LjgzNCw1LjUyMywyMC4wNTIsNS41MjMsMjguODg2LDBjMS45MDEtMS4xOTUsNC4yOTctMS4yODcsNi4yODQtMC4yNDJjMS45ODQsMS4wNDksMy4yNiwzLjA3OCwzLjM0Miw1LjMyNGMwLjA4NywyLjI0LTEuMDI3LDQuMzYzLTIuOTMsNS41NjFjLTMuODM0LDIuNDA2LTguMDQ5LDQuMTQ4LTEyLjQ1Nyw1LjE1NGwxMS45OTQsMTJjMi40NTEsMi40NTcsMi40NDYsNi40MzYtMC4wMTEsOC44OTNjLTIuNDU2LDIuNDQ1LTYuNDM0LDIuNDQ1LTguODg2LTAuMDEybC0xMS43NzktMTEuNzg5bC0xMS43ODUsMTEuNzg5Yy0yLjQ1NSwyLjQ1Ny02LjQzNiwyLjQ1Ny04Ljg4OSwwYy0yLjQ1NC0yLjQ1NS0yLjQ1NC02LjQzNCwwLTguODkxTDY2LjMyLDkzLjgzNkw2Ni4zMiw5My44MzZ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 28px 28px
}

.t228__transp-black .ya-share2__badge {
  background-color: transparent !important
}

.t228__transp-black .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjEgMjN2LTguMjFoMi43NzNsLjQxNS0zLjJIMTUuMVY5LjU0N2MwLS45MjcuMjYtMS41NTggMS41OTYtMS41NThsMS43MDQtLjAwMlY1LjEyNkEyMi43ODcgMjIuNzg3IDAgMCAwIDE1LjkxNyA1QzEzLjQ2IDUgMTEuNzggNi40OTIgMTEuNzggOS4yM3YyLjM2SDl2My4yaDIuNzhWMjNoMy4zMnoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==)
}

.t228__transp-black .ya-share2__container_size_m .ya-share2__counter {
  color: #222;
  padding-left: 0 !important;
  margin-top: 2px
}

.t228__transp-black .ya-share2__counter:before {
  display: none
}

.t228__transp-black .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0Ljg4MyAxOS4zOTZzLjMyNS0uMDM2LjQ5LS4yMThjLjE1NC0uMTY3LjE1LS40OC4xNS0uNDhzLS4wMjMtMS40NjguNjQ4LTEuNjg0Yy42Ni0uMjEzIDEuNTEgMS40MTggMi40MDggMi4wNDYuNjguNDc0IDEuMTk3LjM3IDEuMTk3LjM3bDIuNDA0LS4wMzRzMS4yNTYtLjA4LjY2LTEuMDg0Yy0uMDUtLjA4Mi0uMzQ4LS43NDMtMS43ODgtMi4xMDItMS41MDctMS40MjMtMS4zMDUtMS4xOTIuNTEtMy42NTMgMS4xMDYtMS40OTggMS41NDgtMi40MTIgMS40MS0yLjgwNC0uMTMyLS4zNzMtLjk0NS0uMjc1LS45NDUtLjI3NWwtMi43MDYuMDE3cy0uMi0uMDI4LS4zNS4wNjNjLS4xNDQuMDg4LS4yMzguMjk1LS4yMzguMjk1cy0uNDI4IDEuMTYtMSAyLjE0NmMtMS4yMDQgMi4wOC0xLjY4NiAyLjE5LTEuODgzIDIuMDYtLjQ2LS4zLS4zNDUtMS4yMS0uMzQ1LTEuODU1IDAtMi4wMTcuMy0yLjg1Ny0uNTg2LTMuMDc1LS4yOTUtLjA3Mi0uNTEyLS4xMi0xLjI2NC0uMTI4LS45NjYtLjAxLTEuNzgzLjAwMy0yLjI0Ni4yMzQtLjMwOC4xNTMtLjU0Ni40OTUtLjQuNTE0LjE3OC4wMjUuNTgzLjExLjc5OC40MS4yNzcuMzgyLjI2OCAxLjI0NC4yNjggMS4yNDRzLjE2IDIuMzczLS4zNzMgMi42NjhjLS4zNjUuMjAyLS44NjUtLjIxLTEuOTQtMi4wOTgtLjU1LS45NjctLjk2Ni0yLjAzNi0uOTY2LTIuMDM2cy0uMDgtLjItLjIyMy0uMzA2Yy0uMTczLS4xMy0uNDE2LS4xNy0uNDE2LS4xN2wtMi41Ny4wMTZzLS4zODguMDEtLjUzLjE4MmMtLjEyNS4xNTItLjAxLjQ2Ni0uMDEuNDY2czIuMDE0IDQuNzkgNC4yOTQgNy4yMDJjMi4wOSAyLjIxNCA0LjQ2NSAyLjA2OCA0LjQ2NSAyLjA2OGgxLjA3NnoiICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=)
}

.t228__transp-black .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIzIDguNzNhNy4zOCA3LjM4IDAgMCAxLTIuMTIuNTgzIDMuNzA2IDMuNzA2IDAgMCAwIDEuNjIzLTIuMDQzIDcuMzk3IDcuMzk3IDAgMCAxLTIuMzQ2Ljg5NiAzLjY5MyAzLjY5MyAwIDAgMC02LjI5MyAzLjM2OCAxMC40ODUgMTAuNDg1IDAgMCAxLTcuNjEtMy44NThjLS4zMi41NDUtLjUgMS4xOC0uNSAxLjg1NiAwIDEuMjguNjUgMi40MSAxLjY0MiAzLjA3M2EzLjY4MyAzLjY4MyAwIDAgMS0xLjY3My0uNDYydi4wNDdjMCAxLjc4OCAxLjI3MyAzLjI4IDIuOTYyIDMuNjJhMy43MTggMy43MTggMCAwIDEtMS42NjcuMDYzIDMuNjk3IDMuNjk3IDAgMCAwIDMuNDUgMi41NjRBNy40MSA3LjQxIDAgMCAxIDUgMTkuOTY3YTEwLjQ1MyAxMC40NTMgMCAwIDAgNS42NiAxLjY1OGM2Ljc5NCAwIDEwLjUwOC01LjYyNiAxMC41MDgtMTAuNTA1IDAtLjE2LS4wMDMtLjMyLS4wMS0uNDc4QTcuNTA3IDcuNTA3IDAgMCAwIDIzIDguNzMyeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
}

.t228__transp-black .ya-share2__container_size_m .ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNTBweCIgaGVpZ2h0PSIxNTBweCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1MCAxNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjx0aXRsZT5TaGFwZTwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+PGcgaWQ9IldlbGNvbWUiPjxwYXRoIGlkPSJTaGFwZSIgZmlsbD0iIzIyMjIyMiIgZD0iTTc0Ljk5Nyw0Ni4wMTFjLTQuOTAzLDAuMDA2LTguODc1LDMuOTc5LTguODgzLDguODgzYzAsNC44OTQsMy45ODUsOC44NzksOC44ODMsOC44NzljNC45MDMtMC4wMDgsOC44NzUtMy45NzksOC44OC04Ljg3OUM4My44NzksNDkuOTg5LDc5LjksNDYuMDE3LDc0Ljk5Nyw0Ni4wMTFMNzQuOTk3LDQ2LjAxMXogTTc0Ljk5Nyw3Ni4zMzhjLTExLjgzOC0wLjAxLTIxLjQzNy05LjYwNi0yMS40NDgtMjEuNDQ0YzAuMDA4LTExLjg0Niw5LjYwNC0yMS40NDIsMjEuNDQ4LTIxLjQ1NWMxMS44NDUsMC4wMSwyMS40NTEsOS42MDksMjEuNDU2LDIxLjQ1NUM5Ni40MzgsNjYuNzM0LDg2LjgzOCw3Ni4zMyw3NC45OTcsNzYuMzM4TDc0Ljk5Nyw3Ni4zMzh6IE02Ni4zMiw5My44MzZjLTQuNDEyLTEuMDAyLTguNjI0LTIuNzQ4LTEyLjQ1NS01LjE1NGMtMi45MzktMS44NS0zLjgyMy01LjczNC0xLjk3My04LjY2OGMxLjg0OC0yLjk0MSw1LjcyOC0zLjgyMiw4LjY2Ni0xLjk3NWM4LjgzNCw1LjUyMywyMC4wNTIsNS41MjMsMjguODg2LDBjMS45MDEtMS4xOTUsNC4yOTctMS4yODcsNi4yODQtMC4yNDJjMS45ODQsMS4wNDksMy4yNiwzLjA3OCwzLjM0Miw1LjMyNGMwLjA4NywyLjI0LTEuMDI3LDQuMzYzLTIuOTMsNS41NjFjLTMuODM0LDIuNDA2LTguMDQ5LDQuMTQ4LTEyLjQ1Nyw1LjE1NGwxMS45OTQsMTJjMi40NTEsMi40NTcsMi40NDYsNi40MzYtMC4wMTEsOC44OTNjLTIuNDU2LDIuNDQ1LTYuNDM0LDIuNDQ1LTguODg2LTAuMDEybC0xMS43NzktMTEuNzg5bC0xMS43ODUsMTEuNzg5Yy0yLjQ1NSwyLjQ1Ny02LjQzNiwyLjQ1Ny04Ljg4OSwwYy0yLjQ1NC0yLjQ1NS0yLjQ1NC02LjQzNCwwLTguODkxTDY2LjMyLDkzLjgzNkw2Ni4zMiw5My44MzZ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 28px 28px
}

.t250__text {
  text-align: center;
  margin-bottom: 32px
}

.t250__link {
  text-align: center;
  text-decoration: none;
  display: block;
  width: 110px;
  margin: 0 auto;
  -webkit-transition: opacity ease-in-out 0.2s;
  -moz-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s
}

.t250__link__descr {
  font-size: 12px;
  line-height: 1.55;
  text-align: center
}

.t250__link:hover {
  opacity: .7
}

.t250__icon {
  width: 28px;
  height: 25px;
  margin: 0 auto 12px;
  display: block
}

@media screen and (max-width:600px) {
  .t250__text {
    margin-bottom: 26px
  }
  .t250__img {
    width: 24px;
    height: 24px
  }
}

@media screen and (max-width:460px) {
  .t250__text {
    margin-bottom: 18px
  }
  .t250__img {
    width: 18px;
    height: 21px
  }
}

.t345-container.t345-socials_block {
  height: auto !important;
  padding: 24px 0
}

.t345-container {
  height: 80px
}

.t345 .t345-col {
  height: 100%;
  margin: 0 auto;
  float: none;
  display: block
}

.t345-col.t-col_100 {
  height: 100%;
  margin: 0 auto;
  display: block;
  width: 100%
}

.t345-content {
  display: table;
  height: 100%;
  position: relative;
  width: 100%
}

.t345-socials_block .t345-text__wrapper {
  display: block
}

.t345-text__wrapper {
  display: table-cell;
  vertical-align: middle
}

.t345-text {
  display: inline-block
}

.t345-socials {
  position: absolute;
  top: 50%;
  right: 0;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%)
}

.t345-socials_block .t345-socials {
  position: relative;
  top: auto;
  right: auto;
  display: inline-block;
  margin-top: 14px;
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0)
}

.t345-share,
.t345-social,
.t345 .t-sociallinks {
  display: inline-block;
  vertical-align: middle
}

.t345 .t-sociallinks,
.t345 .t-sociallinks__wrapper,
.t345 .t-sociallinks__item,
.t345-social__wrapper,
.t345-social__item,
.t345-social {
  display: inline-block;
  margin-left: 4px;
  height: 30px;
  -webkit-transition: all ease-in-out .25s;
  -moz-transition: all ease-in-out .25s;
  -o-transition: all ease-in-out .25s;
  transition: all ease-in-out .25s
}

.t345 .t-sociallinks__item:hover,
.t345-social__item:hover {
  opacity: .8
}

.t345-socials_block .t-sociallinks,
.t345-socials_block .t-sociallinks__wrapper,
.t345-socials_block .t-sociallinks__item,
.t345-socials_block .t345-social__wrapper,
.t345-socials_block .t345-social__item,
.t345-socials_block .t345-social {
  margin-left: 0;
  margin-right: 4px
}

.t345-text {
  color: #555
}

.t345 .ya-share2__container_size_m .ya-share2__icon {
  width: 28px;
  height: 28px
}

.t345 .ya-share2__list {
  margin-bottom: 0
}

.t345 .ya-share2__container_size_m .ya-share2__counter {
  line-height: 28px
}

.t345__black-white .ya-share2__badge {
  background-color: #111 !important
}

.t345__transp-white .ya-share2__badge {
  background-color: transparent !important
}

.t345__transp-white .ya-share2__container_size_m .ya-share2__counter:before {
  display: none
}

.t345__transp-white .ya-share2__container_size_m .ya-share2__counter {
  padding-left: 0 !important;
  margin-top: 2px
}

.t345__white-black .ya-share2__badge {
  background-color: #fff !important
}

.t345__white-black .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjEgMjN2LTguMjFoMi43NzNsLjQxNS0zLjJIMTUuMVY5LjU0N2MwLS45MjcuMjYtMS41NTggMS41OTYtMS41NThsMS43MDQtLjAwMlY1LjEyNkEyMi43ODcgMjIuNzg3IDAgMCAwIDE1LjkxNyA1QzEzLjQ2IDUgMTEuNzggNi40OTIgMTEuNzggOS4yM3YyLjM2SDl2My4yaDIuNzhWMjNoMy4zMnoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==)
}

.t345__white-black .ya-share2__container_size_m .ya-share2__counter {
  color: #222
}

.t345__white-black .ya-share2__counter:before {
  background-color: #222
}

.t345__white-black .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0Ljg4MyAxOS4zOTZzLjMyNS0uMDM2LjQ5LS4yMThjLjE1NC0uMTY3LjE1LS40OC4xNS0uNDhzLS4wMjMtMS40NjguNjQ4LTEuNjg0Yy42Ni0uMjEzIDEuNTEgMS40MTggMi40MDggMi4wNDYuNjguNDc0IDEuMTk3LjM3IDEuMTk3LjM3bDIuNDA0LS4wMzRzMS4yNTYtLjA4LjY2LTEuMDg0Yy0uMDUtLjA4Mi0uMzQ4LS43NDMtMS43ODgtMi4xMDItMS41MDctMS40MjMtMS4zMDUtMS4xOTIuNTEtMy42NTMgMS4xMDYtMS40OTggMS41NDgtMi40MTIgMS40MS0yLjgwNC0uMTMyLS4zNzMtLjk0NS0uMjc1LS45NDUtLjI3NWwtMi43MDYuMDE3cy0uMi0uMDI4LS4zNS4wNjNjLS4xNDQuMDg4LS4yMzguMjk1LS4yMzguMjk1cy0uNDI4IDEuMTYtMSAyLjE0NmMtMS4yMDQgMi4wOC0xLjY4NiAyLjE5LTEuODgzIDIuMDYtLjQ2LS4zLS4zNDUtMS4yMS0uMzQ1LTEuODU1IDAtMi4wMTcuMy0yLjg1Ny0uNTg2LTMuMDc1LS4yOTUtLjA3Mi0uNTEyLS4xMi0xLjI2NC0uMTI4LS45NjYtLjAxLTEuNzgzLjAwMy0yLjI0Ni4yMzQtLjMwOC4xNTMtLjU0Ni40OTUtLjQuNTE0LjE3OC4wMjUuNTgzLjExLjc5OC40MS4yNzcuMzgyLjI2OCAxLjI0NC4yNjggMS4yNDRzLjE2IDIuMzczLS4zNzMgMi42NjhjLS4zNjUuMjAyLS44NjUtLjIxLTEuOTQtMi4wOTgtLjU1LS45NjctLjk2Ni0yLjAzNi0uOTY2LTIuMDM2cy0uMDgtLjItLjIyMy0uMzA2Yy0uMTczLS4xMy0uNDE2LS4xNy0uNDE2LS4xN2wtMi41Ny4wMTZzLS4zODguMDEtLjUzLjE4MmMtLjEyNS4xNTItLjAxLjQ2Ni0uMDEuNDY2czIuMDE0IDQuNzkgNC4yOTQgNy4yMDJjMi4wOSAyLjIxNCA0LjQ2NSAyLjA2OCA0LjQ2NSAyLjA2OGgxLjA3NnoiICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=)
}

.t345__white-black .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIzIDguNzNhNy4zOCA3LjM4IDAgMCAxLTIuMTIuNTgzIDMuNzA2IDMuNzA2IDAgMCAwIDEuNjIzLTIuMDQzIDcuMzk3IDcuMzk3IDAgMCAxLTIuMzQ2Ljg5NiAzLjY5MyAzLjY5MyAwIDAgMC02LjI5MyAzLjM2OCAxMC40ODUgMTAuNDg1IDAgMCAxLTcuNjEtMy44NThjLS4zMi41NDUtLjUgMS4xOC0uNSAxLjg1NiAwIDEuMjguNjUgMi40MSAxLjY0MiAzLjA3M2EzLjY4MyAzLjY4MyAwIDAgMS0xLjY3My0uNDYydi4wNDdjMCAxLjc4OCAxLjI3MyAzLjI4IDIuOTYyIDMuNjJhMy43MTggMy43MTggMCAwIDEtMS42NjcuMDYzIDMuNjk3IDMuNjk3IDAgMCAwIDMuNDUgMi41NjRBNy40MSA3LjQxIDAgMCAxIDUgMTkuOTY3YTEwLjQ1MyAxMC40NTMgMCAwIDAgNS42NiAxLjY1OGM2Ljc5NCAwIDEwLjUwOC01LjYyNiAxMC41MDgtMTAuNTA1IDAtLjE2LS4wMDMtLjMyLS4wMS0uNDc4QTcuNTA3IDcuNTA3IDAgMCAwIDIzIDguNzMyeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
}

.t345__white-black .ya-share2__container_size_m .ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNTBweCIgaGVpZ2h0PSIxNTBweCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1MCAxNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjx0aXRsZT5TaGFwZTwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+PGcgaWQ9IldlbGNvbWUiPjxwYXRoIGlkPSJTaGFwZSIgZmlsbD0iIzIyMjIyMiIgZD0iTTc0Ljk5Nyw0Ni4wMTFjLTQuOTAzLDAuMDA2LTguODc1LDMuOTc5LTguODgzLDguODgzYzAsNC44OTQsMy45ODUsOC44NzksOC44ODMsOC44NzljNC45MDMtMC4wMDgsOC44NzUtMy45NzksOC44OC04Ljg3OUM4My44NzksNDkuOTg5LDc5LjksNDYuMDE3LDc0Ljk5Nyw0Ni4wMTFMNzQuOTk3LDQ2LjAxMXogTTc0Ljk5Nyw3Ni4zMzhjLTExLjgzOC0wLjAxLTIxLjQzNy05LjYwNi0yMS40NDgtMjEuNDQ0YzAuMDA4LTExLjg0Niw5LjYwNC0yMS40NDIsMjEuNDQ4LTIxLjQ1NWMxMS44NDUsMC4wMSwyMS40NTEsOS42MDksMjEuNDU2LDIxLjQ1NUM5Ni40MzgsNjYuNzM0LDg2LjgzOCw3Ni4zMyw3NC45OTcsNzYuMzM4TDc0Ljk5Nyw3Ni4zMzh6IE02Ni4zMiw5My44MzZjLTQuNDEyLTEuMDAyLTguNjI0LTIuNzQ4LTEyLjQ1NS01LjE1NGMtMi45MzktMS44NS0zLjgyMy01LjczNC0xLjk3My04LjY2OGMxLjg0OC0yLjk0MSw1LjcyOC0zLjgyMiw4LjY2Ni0xLjk3NWM4LjgzNCw1LjUyMywyMC4wNTIsNS41MjMsMjguODg2LDBjMS45MDEtMS4xOTUsNC4yOTctMS4yODcsNi4yODQtMC4yNDJjMS45ODQsMS4wNDksMy4yNiwzLjA3OCwzLjM0Miw1LjMyNGMwLjA4NywyLjI0LTEuMDI3LDQuMzYzLTIuOTMsNS41NjFjLTMuODM0LDIuNDA2LTguMDQ5LDQuMTQ4LTEyLjQ1Nyw1LjE1NGwxMS45OTQsMTJjMi40NTEsMi40NTcsMi40NDYsNi40MzYtMC4wMTEsOC44OTNjLTIuNDU2LDIuNDQ1LTYuNDM0LDIuNDQ1LTguODg2LTAuMDEybC0xMS43NzktMTEuNzg5bC0xMS43ODUsMTEuNzg5Yy0yLjQ1NSwyLjQ1Ny02LjQzNiwyLjQ1Ny04Ljg4OSwwYy0yLjQ1NC0yLjQ1NS0yLjQ1NC02LjQzNCwwLTguODkxTDY2LjMyLDkzLjgzNkw2Ni4zMiw5My44MzZ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 28px 28px
}

.t345__transp-black .ya-share2__badge {
  background-color: transparent !important
}

.t345__transp-black .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjEgMjN2LTguMjFoMi43NzNsLjQxNS0zLjJIMTUuMVY5LjU0N2MwLS45MjcuMjYtMS41NTggMS41OTYtMS41NThsMS43MDQtLjAwMlY1LjEyNkEyMi43ODcgMjIuNzg3IDAgMCAwIDE1LjkxNyA1QzEzLjQ2IDUgMTEuNzggNi40OTIgMTEuNzggOS4yM3YyLjM2SDl2My4yaDIuNzhWMjNoMy4zMnoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==)
}

.t345__transp-black .ya-share2__container_size_m .ya-share2__counter {
  color: #222;
  padding-left: 0 !important;
  margin-top: 2px
}

.t345__transp-black .ya-share2__counter:before {
  display: none
}

.t345__transp-black .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0Ljg4MyAxOS4zOTZzLjMyNS0uMDM2LjQ5LS4yMThjLjE1NC0uMTY3LjE1LS40OC4xNS0uNDhzLS4wMjMtMS40NjguNjQ4LTEuNjg0Yy42Ni0uMjEzIDEuNTEgMS40MTggMi40MDggMi4wNDYuNjguNDc0IDEuMTk3LjM3IDEuMTk3LjM3bDIuNDA0LS4wMzRzMS4yNTYtLjA4LjY2LTEuMDg0Yy0uMDUtLjA4Mi0uMzQ4LS43NDMtMS43ODgtMi4xMDItMS41MDctMS40MjMtMS4zMDUtMS4xOTIuNTEtMy42NTMgMS4xMDYtMS40OTggMS41NDgtMi40MTIgMS40MS0yLjgwNC0uMTMyLS4zNzMtLjk0NS0uMjc1LS45NDUtLjI3NWwtMi43MDYuMDE3cy0uMi0uMDI4LS4zNS4wNjNjLS4xNDQuMDg4LS4yMzguMjk1LS4yMzguMjk1cy0uNDI4IDEuMTYtMSAyLjE0NmMtMS4yMDQgMi4wOC0xLjY4NiAyLjE5LTEuODgzIDIuMDYtLjQ2LS4zLS4zNDUtMS4yMS0uMzQ1LTEuODU1IDAtMi4wMTcuMy0yLjg1Ny0uNTg2LTMuMDc1LS4yOTUtLjA3Mi0uNTEyLS4xMi0xLjI2NC0uMTI4LS45NjYtLjAxLTEuNzgzLjAwMy0yLjI0Ni4yMzQtLjMwOC4xNTMtLjU0Ni40OTUtLjQuNTE0LjE3OC4wMjUuNTgzLjExLjc5OC40MS4yNzcuMzgyLjI2OCAxLjI0NC4yNjggMS4yNDRzLjE2IDIuMzczLS4zNzMgMi42NjhjLS4zNjUuMjAyLS44NjUtLjIxLTEuOTQtMi4wOTgtLjU1LS45NjctLjk2Ni0yLjAzNi0uOTY2LTIuMDM2cy0uMDgtLjItLjIyMy0uMzA2Yy0uMTczLS4xMy0uNDE2LS4xNy0uNDE2LS4xN2wtMi41Ny4wMTZzLS4zODguMDEtLjUzLjE4MmMtLjEyNS4xNTItLjAxLjQ2Ni0uMDEuNDY2czIuMDE0IDQuNzkgNC4yOTQgNy4yMDJjMi4wOSAyLjIxNCA0LjQ2NSAyLjA2OCA0LjQ2NSAyLjA2OGgxLjA3NnoiICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=)
}

.t345__transp-black .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMjIyIiB2aWV3Qm94PSIwIDAgMjggMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIzIDguNzNhNy4zOCA3LjM4IDAgMCAxLTIuMTIuNTgzIDMuNzA2IDMuNzA2IDAgMCAwIDEuNjIzLTIuMDQzIDcuMzk3IDcuMzk3IDAgMCAxLTIuMzQ2Ljg5NiAzLjY5MyAzLjY5MyAwIDAgMC02LjI5MyAzLjM2OCAxMC40ODUgMTAuNDg1IDAgMCAxLTcuNjEtMy44NThjLS4zMi41NDUtLjUgMS4xOC0uNSAxLjg1NiAwIDEuMjguNjUgMi40MSAxLjY0MiAzLjA3M2EzLjY4MyAzLjY4MyAwIDAgMS0xLjY3My0uNDYydi4wNDdjMCAxLjc4OCAxLjI3MyAzLjI4IDIuOTYyIDMuNjJhMy43MTggMy43MTggMCAwIDEtMS42NjcuMDYzIDMuNjk3IDMuNjk3IDAgMCAwIDMuNDUgMi41NjRBNy40MSA3LjQxIDAgMCAxIDUgMTkuOTY3YTEwLjQ1MyAxMC40NTMgMCAwIDAgNS42NiAxLjY1OGM2Ljc5NCAwIDEwLjUwOC01LjYyNiAxMC41MDgtMTAuNTA1IDAtLjE2LS4wMDMtLjMyLS4wMS0uNDc4QTcuNTA3IDcuNTA3IDAgMCAwIDIzIDguNzMyeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
}

.t345__transp-black .ya-share2__container_size_m .ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNTBweCIgaGVpZ2h0PSIxNTBweCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1MCAxNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjx0aXRsZT5TaGFwZTwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+PGcgaWQ9IldlbGNvbWUiPjxwYXRoIGlkPSJTaGFwZSIgZmlsbD0iIzIyMjIyMiIgZD0iTTc0Ljk5Nyw0Ni4wMTFjLTQuOTAzLDAuMDA2LTguODc1LDMuOTc5LTguODgzLDguODgzYzAsNC44OTQsMy45ODUsOC44NzksOC44ODMsOC44NzljNC45MDMtMC4wMDgsOC44NzUtMy45NzksOC44OC04Ljg3OUM4My44NzksNDkuOTg5LDc5LjksNDYuMDE3LDc0Ljk5Nyw0Ni4wMTFMNzQuOTk3LDQ2LjAxMXogTTc0Ljk5Nyw3Ni4zMzhjLTExLjgzOC0wLjAxLTIxLjQzNy05LjYwNi0yMS40NDgtMjEuNDQ0YzAuMDA4LTExLjg0Niw5LjYwNC0yMS40NDIsMjEuNDQ4LTIxLjQ1NWMxMS44NDUsMC4wMSwyMS40NTEsOS42MDksMjEuNDU2LDIxLjQ1NUM5Ni40MzgsNjYuNzM0LDg2LjgzOCw3Ni4zMyw3NC45OTcsNzYuMzM4TDc0Ljk5Nyw3Ni4zMzh6IE02Ni4zMiw5My44MzZjLTQuNDEyLTEuMDAyLTguNjI0LTIuNzQ4LTEyLjQ1NS01LjE1NGMtMi45MzktMS44NS0zLjgyMy01LjczNC0xLjk3My04LjY2OGMxLjg0OC0yLjk0MSw1LjcyOC0zLjgyMiw4LjY2Ni0xLjk3NWM4LjgzNCw1LjUyMywyMC4wNTIsNS41MjMsMjguODg2LDBjMS45MDEtMS4xOTUsNC4yOTctMS4yODcsNi4yODQtMC4yNDJjMS45ODQsMS4wNDksMy4yNiwzLjA3OCwzLjM0Miw1LjMyNGMwLjA4NywyLjI0LTEuMDI3LDQuMzYzLTIuOTMsNS41NjFjLTMuODM0LDIuNDA2LTguMDQ5LDQuMTQ4LTEyLjQ1Nyw1LjE1NGwxMS45OTQsMTJjMi40NTEsMi40NTcsMi40NDYsNi40MzYtMC4wMTEsOC44OTNjLTIuNDU2LDIuNDQ1LTYuNDM0LDIuNDQ1LTguODg2LTAuMDEybC0xMS43NzktMTEuNzg5bC0xMS43ODUsMTEuNzg5Yy0yLjQ1NSwyLjQ1Ny02LjQzNiwyLjQ1Ny04Ljg4OSwwYy0yLjQ1NC0yLjQ1NS0yLjQ1NC02LjQzNCwwLTguODkxTDY2LjMyLDkzLjgzNkw2Ni4zMiw5My44MzZ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 28px 28px
}

.t415__mainblock {
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1
}

.t415__content {
  padding: 30px 0
}

.t415__hide {
  display: none !important
}

.t415__col {
  display: inline-block;
  padding-right: 32px;
  margin-right: 30px;
  position: relative
}

.t415__col:after {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .2;
  background: #fff;
  width: 1px
}

.t415__col:last-child:after {
  display: none
}

.t415__col:last-child {
  margin-right: 0;
  padding-right: 0
}

.t415__number {
  color: #fff
}

.t415__text {
  color: #fff;
  padding-bottom: 7px
}

.t415__logo {
  margin-bottom: 45px;
  max-width: 280px;
  width: 100%
}

.t415__textwrapper {
  margin: 0 auto 55px
}

.t415__uptitle,
.t415__title,
.t415__descr,
.t415__descr-second {
  color: #fff
}

.t415__uptitle {
  margin-bottom: 18px
}

.t415__descr {
  margin-top: 18px
}

.t415__hint {
  color: #fff;
  margin-top: 45px
}

.t415__descr-second {
  margin: 0 auto 25px
}

.t415__buttons {
  margin-top: 50px
}

.t415__btn {
  height: 56px;
  padding-left: 35px;
  padding-right: 35px
}

@media screen and (max-width:640px) {
  .t415__col {
    padding-right: 20px;
    margin-right: 13px
  }
  .t415__logo {
    margin-bottom: 25px
  }
  .t415__textwrapper {
    margin-bottom: 35px
  }
  .t415__hint {
    margin-top: 35px
  }
  .t415__buttons {
    margin-top: 35px
  }
  .t415__btn {
    display: block;
    margin-right: 0 !important;
    font-size: 14px;
    height: 50px
  }
  .t415__btn-second {
    margin-top: 20px
  }
}

@media screen and (max-width:400px) {
  .t415__number {
    font-size: 24px
  }
}

.t470__title {
  margin-bottom: 120px
}

.t470__descr {
  margin: 110px 0;
  position: relative;
  z-index: 1
}

.t470 .t-btn {
  margin-top: 120px
}

.t470__imgdescrwrapper {
  position: relative;
  display: table
}

.t470__bgimg {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%)
}

.t492__blocktable {
  width: 100%;
  height: 700px;
  margin: 0;
  padding: 0;
  border: 0;
  border-spacing: 0
}

.t492__blocktext {
  width: 50%;
  height: 100%;
  vertical-align: middle
}

.t492__blockimg {
  width: 50%;
  height: 100%;
  vertical-align: middle;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  margin: 0;
  padding: 0
}

.t492__textwrapper {
  margin: 10%
}

.t492 .t-descr {
  padding-top: 20px
}

.t492__imgmobile {
  width: auto;
  max-width: 100%;
  vertical-align: middle;
  margin: 0
}

.t492__btn-container {
  width: 100%
}

.t492__btn-wrapper {
  display: inline-block;
  padding-top: 60px
}

.t492__btntext-wrapper {
  display: inline-block;
  padding-top: 45px
}

@media screen and (max-width:980px) {
  .t492__blockimg {
    display: none
  }
  .t492__blockimgmobile {
    display: block !important;
    text-align: center;
    width: 100%;
    padding: 60px 0 0 0
  }
  .t492__blocktable {
    height: auto
  }
  .t492__blocktext {
    width: 100%
  }
  .t492__btn-wrapper {
    padding-top: 0;
    padding-bottom: 40px
  }
  .t492__btntext-wrapper {
    padding-top: 0;
    padding-bottom: 40px
  }
}

@media screen and (max-width:640px) {
  .t492 .t-title {
    padding-top: 50px;
    width: 100%
  }
  .t492 .t-descr {
    padding-bottom: 50px;
    width: 100%
  }
  .t492__textwrapper {
    margin: 20px
  }
}

.t494__text-bottom-margin {
  margin-bottom: 50px
}

.t494__text {
  color: #fff
}

.t494 .t-sociallinks {
  margin-bottom: 50px
}

.t494 .t-sociallinks__item {
  display: inline-block;
  margin: 4px 1px 0
}

.t547 .t-section__topwrapper {
  margin-bottom: 105px
}

.t547 .t-section__title {
  margin-bottom: 40px
}

.t547 .t-section__descr {
  max-width: 560px
}

.t547 .t-section__bottomwrapper {
  margin-top: 105px
}

.t547__mainblock:after,
.t547__mainblock:before {
  content: '';
  display: table;
  clear: both
}

.t547__item {
  position: relative;
  padding-bottom: 30px
}

.t547__item:last-child {
  padding-bottom: 0 !important
}

.t547__block {
  padding-right: 40px
}

.t547__flipped .t547__block {
  padding-right: 0;
  padding-left: 40px
}

.t547__mainblock {
  margin: 0 auto
}

.t547__line {
  position: absolute;
  width: 2px;
  top: 0;
  background: #222;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto
}

.t547__item:first-child .t547__line {
  top: 10px
}

.t547__item:last-child .t547__line {
  height: 10px;
  bottom: auto
}

.t547__col {
  width: 50%;
  text-align: right
}

.t547__flipped {
  float: right !important;
  text-align: left
}

.t547__circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #222;
  position: absolute;
  right: 0;
  left: 0;
  top: 5px;
  margin: 0 auto;
  border: 2px solid #fff
}

.t547__img {
  width: 100%;
  display: block;
  margin-bottom: 14px;
  margin-left: auto
}

.t547__flipped .t547__img {
  margin-left: 0
}

.t547__title {
  margin-bottom: 6px;
  margin-top: 2px
}

.t547__descr {
  margin-top: 10px;
  margin-bottom: 5px
}

@media only screen and (max-width:960px) {
  .t547 .t-section__bottomwrapper {
    margin-top: 45px
  }
  .t547 .t-section__topwrapper {
    margin-bottom: 45px
  }
  .t547 .t-section__title {
    margin-bottom: 20px
  }
  .t547__container {
    padding: 0 20px;
    box-sizing: border-box
  }
}

@media only screen and (max-width:640px) {
  .t547__block {
    padding-right: 30px
  }
  .t547__circle {
    width: 12px;
    height: 12px
  }
  .t547__flipped .t547__block {
    padding-left: 30px
  }
}

@media only screen and (max-width:540px) {
  .t547__block {
    padding-right: 0;
    padding-left: 20px
  }
  .t547__title {
    margin-bottom: 0
  }
  .t547__descr {
    margin-top: 5px
  }
  .t547__circle {
    width: 10px;
    height: 10px;
    top: 8px;
    left: -2px;
    right: auto
  }
  .t547__col {
    float: right;
    text-align: left;
    width: 100%
  }
  .t547__flipped .t547__block {
    padding-left: 20px
  }
  .t547__line {
    right: auto;
    left: 4px
  }
  .t547__img {
    margin-left: 0
  }
}

.t667__container_indent {
  padding: 0 40px 0 40px;
  box-sizing: border-box
}

.t667__row {
  box-sizing: border-box;
  font-size: 0
}

.t667__row_indent-10px {
  padding: 0 10px 10px 10px
}

.t667__row_indent-20px {
  padding: 0 20px 20px 20px
}

.t667__row_indent-40px {
  padding: 0 40px 40px 40px
}

.t667__row_indent-10px:first-child {
  padding-top: 10px
}

.t667__row_indent-20px:first-child {
  padding-top: 20px
}

.t667__row_indent-40px:first-child {
  padding-top: 40px
}

.t667_no-bottom-indent .t667__row:last-child {
  padding-bottom: 0px !important
}

.t-container .t667__row {
  padding-left: 20px;
  padding-right: 20px
}

.t667__container_indent .t667__row {
  padding-left: 0px !important;
  padding-right: 0px !important
}

.t667__tile {
  display: inline-block;
  box-sizing: border-box;
  width: 50%
}

.t667__tile_100 {
  width: 100%
}

.t667__bgimg_16-9 {
  padding-bottom: 56%
}

.t667__bgimg_4-3 {
  padding-bottom: 75%
}

.t667__bgimg_3-2 {
  padding-bottom: 66%
}

.t667__bgimg_1-1 {
  padding-bottom: 100%
}

.t667__bgimg_2-3 {
  padding-bottom: 150%
}

.t667__bgimg_3-4 {
  padding-bottom: 133%
}

.t667__bgimg_9-16 {
  padding-bottom: 177%
}

.t667__row_indent-10px .t667__tile_left {
  padding-right: 5px
}

.t667__row_indent-10px .t667__tile_right {
  padding-left: 5px
}

.t667__row_indent-20px .t667__tile_left {
  padding-right: 10px
}

.t667__row_indent-20px .t667__tile_right {
  padding-left: 10px
}

.t667__row_indent-40px .t667__tile_left {
  padding-right: 20px
}

.t667__row_indent-40px .t667__tile_right {
  padding-left: 20px
}

@media screen and (max-width:1200px) {
  .t-container .t667__row {
    padding-left: 10px;
    padding-right: 10px
  }
}

@media screen and (max-width:960px) {
  .t667__container_indent,
  .t-container .t667__row {
    padding-left: 20px;
    padding-right: 20px
  }
  .t667__row_indent-20px {
    padding: 0 10px 10px 10px
  }
  .t667__row_indent-40px {
    padding: 0 20px 20px 20px
  }
  .t667__row_indent-20px:first-child {
    padding-top: 10px
  }
  .t667__row_indent-40px:first-child {
    padding-top: 20px
  }
  .t667__row_indent-20px .t667__tile_left {
    padding-right: 5px
  }
  .t667__row_indent-20px .t667__tile_right {
    padding-left: 5px
  }
  .t667__row_indent-40px .t667__tile_left {
    padding-right: 10px
  }
  .t667__row_indent-40px .t667__tile_right {
    padding-left: 10px
  }
}

.t698__mainwrapper {
  padding: 0 20px
}

.t698__mainblock {
  margin: 0 auto
}

.t698__mainblock_left {
  text-align: left
}

.t698__title {
  color: #fff;
  margin-bottom: 40px
}

.t698__descr {
  color: #fff;
  margin-bottom: 50px
}

.t698__nomargin .t698__descr {
  margin-bottom: 30px
}

.t698 .t-form__inputsbox {
  -webkit-transition: max-height 0.3s cubic-bezier(.19, 1, .22, 1);
  transition: max-height 0.3s cubic-bezier(.19, 1, .22, 1);
  max-height: 15000px
}

.t698__inputsbox_hidden.t-form__inputsbox {
  overflow: hidden;
  max-height: 0;
  opacity: 0
}

.t698 .t-form__inputsbox {
  max-width: 560px;
  margin: 0 auto;
  text-align: left
}

.t698__mainblock_left .t-form__inputsbox {
  margin-left: 0
}

.t698 .t-input-group {
  margin-bottom: 20px
}

.t698 .t-input {
  height: 56px;
  background-color: transparent;
  -webkit-appearance: none
}

.t698 .t-input__vis-ph {
  top: 20px
}

.t698 .t-input-block textarea {
  padding-top: 17px
}

.t698 .t-input-block textarea.t-input_pvis {
  padding-top: 26px
}

.t698 .t-input-title {
  padding-bottom: 5px;
  color: #fff
}

.t698 .t-input-subtitle {
  padding-bottom: 15px;
  color: #fff
}

.t698 .t-input-group_rd .t-input-subtitle {
  padding-bottom: 5px
}

.t698 .t-radio__indicator,
.t698 .t-checkbox__indicator,
.t698 .t-checkbox__indicator:after {
  border-color: #fff
}

.t698 .t-radio__indicator:after {
  background: #fff
}

.t698 .t-radio__control,
.t698 .t-checkbox__control,
.t698 .t-inputquantity__btn {
  color: #fff
}

.t698 .t-form__submit {
  display: block;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 10px
}

.t698 .t-submit {
  height: 56px;
  padding: 0 20px;
  width: 100%
}

.t698 .t-form_bbonly .t-input-title {
  padding-bottom: 0;
  margin-bottom: 0
}

.t698 .t-form_bbonly .t-input-subtitle {
  padding-bottom: 0;
  padding-top: 5px
}

.t698__form-bottom-text {
  color: #fff;
  margin-top: 30px;
  margin-bottom: 30px
}

@media screen and (max-width:640px) {
  .t698__title {
    margin-bottom: 18px
  }
  .t698__descr {
    margin-bottom: 30px
  }
  .t698 .t-input-block textarea {
    padding-top: 12px
  }
}

@media screen and (max-width:480px) {
  .t698 .t-inputtime,
  .t698 .t-datepicker__wrapper {
    max-width: 100%
  }
  .t698__text {
    font-size: 10px
  }
  .t698 .t-input-group {
    margin-bottom: 15px
  }
}

.t738 .t-section__topwrapper {
  margin-bottom: 105px
}

.t738 .t-section__title {
  margin-bottom: 40px
}

.t738 .t-section__descr {
  max-width: 560px
}

.t738 .t-section__bottomwrapper {
  margin-top: 105px
}

.t738 .t-slds__arrow_wrapper {
  width: 5%
}

.t738__slds-wrapper {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center
}

.t738__linkwrapper {
  margin: 0 auto
}

.t738__img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto
}

.t738__item {
  padding: 20px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin: 0
}

.t738__item_4-in-row {
  width: 25%
}

.t738__item_6-in-row {
  width: 16.666%
}

.t738__greyonhovercolor {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray
}

.t738__greyonhovercolor:hover {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
  filter: none
}

.t738__alphaonhover {
  opacity: .5
}

.t738__alphaonhover:hover {
  opacity: 1
}

.t738__width_6 {
  max-width: 600px
}

.t738__width_7 {
  max-width: 700px
}

.t738__width_8 {
  max-width: 800px
}

.t738__width_9 {
  max-width: 900px
}

.t738__width_10 {
  max-width: 1000px
}

.t738__width_11 {
  max-width: 1100px
}

.t738__width_12 {
  max-width: 1200px
}

.t738__width_100 {
  max-width: 100%
}

@media screen and (max-width:1200px) {
  .t738__item {
    padding: 10px
  }
  .t738__width_6 {
    max-width: 480px
  }
  .t738__width_7 {
    max-width: 560px
  }
  .t738__width_8 {
    max-width: 640px
  }
  .t738__width_9 {
    max-width: 720px
  }
  .t738__width_10 {
    max-width: 800px
  }
  .t738__width_11 {
    max-width: 880px
  }
  .t738__width_12 {
    max-width: 960px
  }
  .t738__width_100 {
    max-width: 100%
  }
}

@media screen and (max-width:960px) {
  .t738 .t-section__bottomwrapper {
    margin-top: 45px
  }
  .t738 .t-section__topwrapper {
    margin-bottom: 45px
  }
  .t738 .t-section__title {
    margin-bottom: 20px
  }
  .t738__item_6-in-row {
    width: 33.333%
  }
  .t738__witharrows .t-width .t-slds__wrapper {
    padding: 0 35px
  }
}

@media screen and (max-width:640px) {
  .t738__item_4-in-row {
    width: 50%
  }
}

@media screen and (max-width:440px) {
  .t738__item_6-in-row {
    width: 50%
  }
}

.t001__wrapper {
  padding-top: 42px;
  padding-bottom: 42px;
}

.t001__uptitle {
  color: #ffffff;
  padding-bottom: 60px;
  padding-top: 30px;
}

.t001__title {
  color: #fff;
  padding: 24px 0px 38px 0px;
  letter-spacing: 0.5px;
}

.t001__descr {
  color: #fff;
  padding: 0px 0px 30px 0px;
}

.t001__descr_center {
  max-width: 700px;
  margin: 0 auto;
}

.t001__descr_center a {
  color: #fff !important;
  font-weight: 600;
}

@media screen and (max-width:640px) {
  .t001__title {
    padding-left: 10px;
    padding-right: 10px;
  }
  .t001__uptitle {
    padding-left: 10px;
    padding-right: 10px;
  }
  .t001__descr {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}
